@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_anchor {
  @include section;
  .inner {
    @include container;
    ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: vw(8px);
      @include mq {
        gap: pc(12px);
      }
      li {
        position: relative;
        a {
          @include hashtag(large);
          background-color: var(--color-surface);
          &::before {
            content: '';
            width: vw(10px);
            aspect-ratio: 1 / 1;
            background-image: url(/images/arrow_bottom.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            @include mq {
              width: pc(16px);
            }
          }
        }
      }
    }
  }
}
