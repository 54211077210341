@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_map{
  @include module;
  .inner {
    @include container;
    .map {
      margin-inline: auto;
      .map_wrap_outer {
        width: 100%;
        &.shrink {
          margin-inline: auto;
        }
        .map_wrap_inner {
          padding-top: 56.25%;
          position: relative;
          display: block;
          width: 100%;
          @include mq {
            width: 100%;
          }
          iframe {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            border-radius: var(--border-radius-small);
          }
        }
      }
      .caption {
        &.shrink {
          margin-inline: auto;
        }
      }
    }
  }
}
