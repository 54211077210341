@charset "UTF-8";

@import "mixins";

.component.type_catchcopy {
  @include section;
  &.withbg {
    padding-bottom: vw(--margin-section);
    background-repeat: no-repeat;
    background-position: right bottom;
    @include mq {
      padding-bottom: pc(--margin-section-pc);
    }
    + * {
      margin-top: unset;
      @include mq {
        margin-top: unset;
      }
    }
  }
  .inner {
    @include container;
    @include mq {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-column-gap: pc(--gap-2col-row-pc);
      grid-row-gap: 0;
    }
    .heading {
      @include mq {
        grid-area: 1 / 1 / 2 / 3;
      }
      h3 {
        @include heading-small;
      }
    }
    .copy {
      @include mq {
        grid-area: 2 / 1 / 3 / 2;
      }
      h4 {
        margin-top: vw(24px);
        font-size: vw(--fs-xl);
        font-weight: var(--bold);
        line-height: var(--line-height-xsmall);
        @include mq {
          margin-top: pc(32px);
          font-size: pc(--fs-xxxl);
        }
      }
      .button {
        display: none;
        @include mq {
          @include button;
          margin-top: pc(64px);
        }
        a {
          text-align: center;
        }
      }
    }
    .text {
      margin-top: vw(32px);
      @include mq {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: pc(32px);
      }
    }
    .button {
      margin-top: vw(24px);
      @include button;
      @include mq {
        display: none;
      }
      a {
        text-align: center;
        color: var(--color-text-on-accent);
        text-decoration: none;
      }
    }
  }
}
