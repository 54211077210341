@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_panel {
  @include module;
  &.set2 {
    .inner {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, auto);
      grid-gap: vw(60px) 0;
      @include mq {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: pc(60px) pc(60px);
      }
    }
  }
  &.set3 {
    .inner {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, auto);
      grid-gap: vw(60px) 0;
      @include mq {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: pc(40px) pc(25px);
      }
    }
  }
  &.set4 {
    .inner {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, auto);
      grid-gap: vw(60px) 0;
      @include mq {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: pc(20px) pc(30px);
      }
      .unit {
        .title {
          h3 {
            @include mq {
              font-size: pc(--fs-l);
            }
          }
        }
        .desc {
          @include mq {
            font-size: pc(--fs-s);
          }
        }
        .button {
          a {
            @include mq {
              @include button(xsmall);
            }
          }
        }
      }
    }
  }
  .inner {
    @include container;
    @include mq {
      display: flex;
      justify-content: flex-start;
      gap: pc(25px);
    }
    .unit {
      @include mq {
        max-width: pc(460px);
        flex: 1;
        position: relative;
      }
      .photo {
        @include mq {
          display: flex;
          align-items: center;
        }
        a {
          @include mq {
            @include hoverOp;
          }
        }
        img {
          border-radius: var(--border-radius-small);
        }
      }
      .title {
        margin-top: vw(32px);
        @include mq {
          margin-top: pc(38px);
        }
        h3 {
          font-weight: var(--bold);
          font-size: vw(--fs-l);
          color: var(--color-third-weak);
          @include mq {
            font-size: pc(--fs-xl);
          }
          a {
            text-decoration: none;
            color: var(--color-black);
            @include mq {
              @include hoverOp;
            }
          }
        }
      }
      .desc {
        margin-top: vw(16px);
        font-size: vw(--fs-m);
        line-height: var(--line-height-medium);
        @include mq {
          margin-top: pc(26px);
          font-size: pc(--fs-m);
        }
      }
      .button {
        margin-top: vw(24px);
        text-align: center;
        @include mq {
          margin-top: pc(32px);
        }
        a {
          @include button(small);
          margin-inline: auto;
        }
      }
    }
  }
}
