@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_column {
  @include section;
  .container {
    @include container;
    .inner {
      background-color: var(--color-surface);
      padding: vw(36px) vw(24px);
      gap: 0;
      border-radius: var(--border-radius-small);
      @include mq {
        padding: pc(60px) pc(50px);
        display: grid;
        grid-template-columns: 38% 62%;
        grid-template-rows: auto auto 1fr;
        border-radius: var(--border-radius-small);
      }
      &.textonly {
        display: block;
      }
      h3 {
        @include heading-small;
        @include mq {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }
      }
      .photo {
        padding-bottom: vw(10px);
        @include mq {
          grid-column: 1 / 2;
          grid-row: 1 / 4;
          padding-right: pc(55px);
          padding-bottom: unset;
        }
        .child {
          margin-top: vw(20px);
          text-align: center;
          @include mq {
            margin-top: pc(20px);
            &:first-of-type {
              margin-top: 0;
            }
          }
          .image_sp {
            padding-bottom: 0;
            max-width: vw(350px);
            margin-inline: auto;
            @include mq {
              display: none;
            }
          }
          .image_pc {
            display: none;
            padding-bottom: 0;
            @include mq {
              display: inline;
            }
          }
          .caption {
            text-align: center;
            margin-top: vw(8px);
            font-size: vw(--fs-xs);
            line-height: var(--line-height-small);
            font-weight: var(--bold);
            @include mq {
              margin-top: pc(16px);
              font-size: pc(--fs-s);
            }
            &.left {
              text-align: left;
            }
            &.center {
              text-align: center;
            }
            &.right {
              text-align: right;
            }
          }
          img {
            border-radius: var(--border-radius-small);
          }
        }
      }
      h4 {
        font-weight: var(--bold);
        margin-top: vw(20px);
        font-size: vw(--fs-s);
        line-height: var(--line-height-small);
        @include mq {
          margin-top: pc(20px);
          font-size: pc(--fs-m);
          grid-column: 2 / 3;
          grid-row: 2 / 3;
        }
      }
      .text {
        padding-top: vw(20px);
        @include mq {
          padding-top: pc(20px);
          grid-column: 2 / 3;
          grid-row: 3 / 4;
        }
        .button {
          a {
            @include button(small);
            margin-inline: auto;
            @include mq {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  &.reverse {
    .container {
      .inner {
        @include mq {
          grid-template-columns: 62% 38%;
          grid-template-rows: auto auto 1fr;
        }
        h3 {
          @include mq {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
          }
        }
        .photo {
          @include mq {
            grid-column: 2 / 3;
            grid-row: 1 / 4;
            padding-right: 0;
            padding-left: 55px;
          }
        }
        h4 {
          @include mq {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
          }
        }
        .text {
          @include mq {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
          }
        }
      }
    }
  }
  &.vertical {
    .container {
      .inner {
        @include mq {
          display: block;
        }
        .photo {
          @include mq {
            padding: 40px 0 20px;
            display: flex;
            justify-content: center;
          }
          .child {
            @include mq {
              width: 100%;
              margin-right: 30px;
              margin-top: 0;
              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
  &.vertical_reverse {
    .container {
      .inner {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto auto;
        h3 {
          grid-row: 1 / 2;
          grid-column: auto;
        }
        h4 {
          grid-row: 2 / 3;
          grid-column: auto;
        }
        .photo {
          grid-row: 4 / 5;
          grid-column: auto;
          padding-bottom: 0;
          margin-top: 10px;
          @include mq {
            padding-right: 0;
            padding-left: 0;
            padding-bottom: 10px;
            padding-top: 0;
            display: flex;
            justify-content: center;
          }
          .child {
            @include mq {
              margin-top: 35px;
              width: 100%;
              margin-right: 30px;
              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
        .text {
          grid-row: 3 / 4;
          grid-column: auto;
        }
      }
    }
  }
}
