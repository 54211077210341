@charset "UTF-8";
@import "mixins";

.p_heading_large {
  h1, h2 {
    font-size: vw(32px);
    font-family: var(--en);
    font-weight: var(--bold);
    text-transform: uppercase;
    text-indent: -.1em;
    grid-area: title;
    @include mq() {
      font-size: pc(72px);
    }
    &.ja {
      font-size: vw(30px);
      @include mq() {
        font-size: pc(68px);
      }
    }
    &.reverse {
      color: var(--color-text-on-accent);
    }
  }
  p {
    font-size: vw(--fs-xs);
    font-weight: var(--bold);
    display: grid;
    grid-template-columns: auto 1fr;
    gap: vw(7px);
    margin-top: vw(2px);
    line-height: var(--line-height-small);
    grid-area: desc;
    @include mq() {
      gap: pc(13px);
      margin-top: pc(13px);
    }
    &::before {
      content: '';
      display: block;
      height: 1lh;
      background-image: url('/images/icon_heading.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: vw(7px);
      @include mq() {
        width: pc(13px);
      }
    }
    @include mq() {
      font-size: pc(22px);
    }
    .sp {
      @include mq() {
        display: none;
      }
    }

  }
  .illust {
    grid-area: illust;
  }
}
.m_title {
  margin-top: vw(100px);
  position: relative;
  z-index: 2;
  @include mq() {
    margin-top: pc(180px);
  }
  &.top-negative {
    margin-top: vw(220px);
    @include mq() {
      margin-top: pcmax(-520px);
    }
  }
  &:has(.about) + .component {
    margin-top: vw(-15px);
    @include mq() {
      margin-top: pc(-42px);
    }
  }
  .inner {
    @include container;
    &.solutions {
      .p_heading_large {
        display: grid;
        grid-template: 
          'title title' auto
          'desc illust' auto
          / auto auto;
        column-gap: vw(20px);
        @include mq() {
          grid-template: 
          'illust title' 1fr
          'illust desc' auto
          / 1fr auto;
        }
        h1 {
          @include mq() {
            align-self: self-end;
          }
        }
        p {
          @include mq() {
            justify-self: end;
          }
        }
        .illust {
          margin-top: vw(20px);
          @include mq() {
            margin-top: unset;
          }
          img {
            width: vw(181px);
            @include mq() {
              width: pc(527px);
            }
          }
        }
      }
    }
    &.column {
      .p_heading_large {
        display: grid;
        grid-template: 
          'title illust' auto
          'desc illust' 1fr
          / auto auto;
        column-gap: vw(20px);
        @include mq() {
          grid-template: 
            'title illust' 55%
            'desc illust' 45%
            / auto auto;
          column-gap: pc(20px);
        }
        h1 {
          @include mq() {
            align-self: self-end;
          }
        }
        p {
          @include mq() {
            align-self: self-start;
          }
        }
        .illust {
          margin-top: vw(17px);
          justify-self: end;
          @include mq() {
            margin-top: unset;
          }
          img {
            width: vw(87px);
            @include mq() {
              width: pc(280px);
            }
          }
        }
      }
    }
    &.about {
      .p_heading_large {
        @include mq() {
          display: grid;
          grid-template: 
            'title illust' auto
            'desc illust' 1fr
            / 1fr 1fr
        }
        h1 {
          @include mq() {
            align-self: self-start;
          }
        }
        p {
          @include mq() {
            align-self: self-start;
          }
        }
        .illust {
          display: block;
          margin-top: vw(42px);
          text-align: right;
          @include mq() {
            justify-self: end;
            margin-top: unset;
          }
          img {
            width: vw(230px);
            @include mq() {
              width: pc(485px);
            }
          }
        }
      }
    }
  }

}
.p_button {
  a {
    @include button;
  }
}
.roll-text, #workstitle {
  &.is-active {
    .parent {
      .child {
        i {
          translate: 0 0;
          opacity: 1;
        }
      }
    }
  }
  .parent {
    .child {
      display: flex;
      i {
        will-change: transform, opacity;
        backface-visibility: hidden;
        transition: translate .5s cubic-bezier(.17,.89,.6,1.18), opacity .5s cubic-bezier(.17,.89,.6,1.18);
        translate: 0 .4em;
        opacity: 0;
        letter-spacing: .05em;
        $i: 1;
        @while $i < 20 {
          &:nth-child(#{$i}) {
            transition-delay: $i / 20 + s;
          }
          $i: $i + 1;
        }
      }
    }
  }
}
#mv_bg {
  display: block;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-image: url(/images/mv_bg.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  position: absolute;
  top: 0;
  @include mq() {
    background-image: url(/images/mv_bg_pc.svg);
    aspect-ratio: 1440 / 1033;
  }
}
.m_mainvisual {
  margin-top: vw(42px);
  @include mq {
    margin-top: unset;
  }
  .inner {
    @include container;
    display: grid;
    grid-template : 
     "image" auto
     "text" auto
     / 1fr;
     gap: vw(42px);
     @include mq {
      grid-template : 
       "text image" auto
       / 1fr auto;
       align-items: end;
      gap: pc(42px);
     }
    .image {
      grid-area: image;
      text-align: center;
      .branding {
        svg {
          width: vw(290px) !important;
          @include mq {
            width: pc(600px) !important;
          }
        }
      }
      .digitalshift {
        svg {
          width: vw(287px) !important;
          @include mq {
            width: pc(600px) !important;
          }
        }
      }
      .teambuilding {
        svg {
          width: vw(280px) !important;
          @include mq {
            width: pc(590px) !important;
          }
        }
      }
      img {
        max-width: 100%;
        &.process {
          @include mq {
            width: pc(573px);
          }

        }
        &.solutions {
          width: vw(320px);
          @include mq {
            width: pc(745px);
          }
        }
        &.branding {
          width: vw(267px);
          @include mq {
            width: pc(581px);
          }
        }
        &.digitalshift {
          width: vw(287px);
          @include mq {
            width: pc(525px);
          }
        }
        &.teambuilding {
          width: vw(260px);
          @include mq {
            width: pc(527px);
          }
        }
      }
    }
    .text {
      grid-area: text;
      @include mq {
        padding-top: pc(130px);
        padding-bottom: pc(30px);
      }
      h2 {
        font-size: vw(24px); 
        font-weight: var(--bold);
        line-height: var(--line-height-xsmall);
        color: var(--color-third);
        @include mq {
          font-size: pc(48px); 
        }
        &.large {
          font-size: vw(36px);
          color: var(--color-black);
          @include mq {
            font-size: pc(64px); 
          }

        }
        
      }
      p {
        margin-top: vw(24px);
        line-height: var(--line-height-large);
        @include mq {
          margin-top: pc(64px);
          font-size: pc(18px); 
        }
      }
    }
  }
}
.m_boxlist {
  @include module;
  .inner {
    @include container;
    .unit {
      @include parts;
      background-color: var(--color-surface);
      padding: vw(36px) vw(30px);
      border-radius: var(--border-radius);
      @include mq {
        padding: pc(36px) pc(60px);
        &:has(.image) {
          display: grid;
          grid-template:
          "text image" auto
          / 1fr auto;
          align-items: end;
          gap: pc(30px);
        }
      }
      &:first-child {
        margin-top: unset;
      }
      &.gray {
        background-color: var(--color-bg);
        .text {
          h3 {
            color: var(--color-third-weak);
          }
          .desc {
            margin-top: vw(14px);
            @include mq {
              margin-top: pc(24px);
      
            }
          }
        }
      }
      &.secondary {
        background-color: var(--color-surface);
        border: vw(2px) solid var(--color-second);
        @include mq {
          padding-top: pc(42px);
          border-width: pc(4px);
        } 
      }
      &.horizontal {
        .text {
          @include mq {
            display: grid;
            grid-template: 
            "tag tag" auto
            "title desc" auto
            / 40% 55%;
            gap: 5%;
            .desc {
              align-self: end;
              padding-bottom: pc(5px);
            }
          } 

        }

      }
      .image {
        text-align: center;
        @include mq {
          padding-bottom: pc(5px);
          grid-area: image;
        }
        + .text {
          @include parts;
          @include mq {
            margin-top: unset;
          }
        }
        .branding {
          svg {
            width: vw(186px) !important;
            @include mq {
              width: pc(202px) !important;
            }
          }
        }
        .digitalshift {
          svg {
            width: vw(203px) !important;
            @include mq {
              width: pc(202px) !important;
            }
          }
        }
        .teambuilding {
          svg {
            width: vw(177px) !important;
            @include mq {
              width: pc(200px) !important;
            }
          }
        }
        img {
          &.branding {
            width: vw(186px);
            @include mq {
              width: pc(192px);
            }
          }
          &.digitalshift {
            width: vw(203px);
            @include mq {
              width: pc(203px);
            }
          }
          &.teambuilding {
            width: vw(177px);
            @include mq {
              width: pc(190px);
            }
          }
        }
      }
      .text {
        @include mq {
          grid-area: text;
        }
        > :first-child {
          margin-top: unset;
        }
        .num {
          display: block;
          color: var(--color-third-weakest);
          font-family: var(--en);
          font-size: vw(--fs-xxxs);
          @include mq {
            font-size: pc(--fs-xs);
    
          }
          + .tag {
            margin-top: vw(5px);
            @include mq {
              margin-top: pc(8px);
      
            }
          }
          + h3 {
            margin-top: vw(5px);
            @include mq {
              margin-top: pc(5px);
      
            }
          }
        }
        .tag {
          grid-area: tag;
          color: var(--color-third-weak);
          font-family: var(--en);
          font-weight: var(--bold);
          text-transform: uppercase;
          @include mq {
            font-size: pc(--fs-l);
    
          }


        }
        h3 {
          @include parts;
          grid-area: title;
          font-weight: var(--bold);
          font-size: vw(--fs-xl);
          line-height: var(--line-height-xsmall);
          font-family: var(--en);
          @include mq {
            font-size: pc(--fs-xxl);
    
          }
          &.short {
            margin-top: vw(3px);
            @include mq {
              margin-top: pc(5px);
            }

          }
          &.firstletter {
            &::first-letter {
              border-bottom: 3px solid var(--color-second);
            }

          }

        }
        .desc {
          grid-area: desc;
          margin-top: vw(10px);
          font-size: vw(--fs-m);
          line-height: var(--line-height-large);
          @include mq {
            margin-top: pc(12px);
            font-size: pc(--fs-l);
          }
          &:has(strong) {
            @include parts;

          }
          strong {
            color: var(--color-third-weak);
          }
        }
        ul.desc {
          list-style: disc;
          margin-left: 1.5em;

        }
      }
    }
  }
}
.bgwrapper {
  @include section;
  border-radius: 0 var(--border-radius-large) 0 var(--border-radius-large);
  padding-block: vw(60px);
  position: relative;
  background-color: var(--bg);
  @include mq {
    background-color: transparent;
    padding-block: pc(120px);
    border-radius: unset;
    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      width: calc(100vw - pc(50px));
      left: calc((50vw - pc(655px)) * -1);
      background-color: var(--bg);
      z-index: -1;
      border-radius: var(--border-radius);
    }
  }
  .component:first-of-type {
    margin-top: unset;
  }
}
.with_footer_bg {
  @include section;
  background: var(--color-surface);
  border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
  padding-block: vw(64px);
  @include mq() {
    padding-block: pc(140px) pc(140px);
    border-radius: var(--border-radius-xlarge) var(--border-radius-xlarge) 0 0;
  }
  .inner {
    @include container;
  }
}
.aside_message {
  .inner {
    .aside_message_inner {
      @include module;
      @include mq {
        display: grid;
        grid-template:
        "image text" auto
        / 55% 40%;
        align-content: stretch;
        gap: 5%;
      }
      .image {
        grid-area: image;
      }
      .text {
        @include module;
        grid-area: text;
        @include mq {
          margin-top: unset;
          display: grid;
          grid-template-rows: auto auto auto auto 1fr;
        }
        h2 {
          font-size: vw(--fs-xl);
          font-weight: var(--bold);
          line-height: var(--line-height-xsmall);
          @include mq {
            font-size: pc(--fs-xxxl);
            
          }
        }
        .position {
          margin-top: vw(24px);
          font-size: vw(--fs-xxs);
          line-height: var(--line-height-xsmall);
          @include mq {
            margin-top: pc(32px);
            font-size: pc(--fs-s);
          }
        }
        h3 {
          margin-top: vw(12px);
          font-size: vw(--fs-l);
          font-weight: var(--bold);
          @include mq {
            margin-top: pc(12px);
            font-size: pc(--fs-xl);
          }
        }
        .en {
          margin-top: vw(8px);
          font-size: vw(--fs-xxs);
          font-weight: var(--demi);
          font-family: var(--en);
          @include mq {
            margin-top: pc(8px);
            font-size: pc(--fs-xs);
          }
        }
        .button {
          margin-top: vw(24px);
          @include mq {
            margin-top: pc(24px);
            align-self: end;
          }
          a {
            @include button(small);
            margin-left: auto;
          }
        }

      }

    }
  }
}
.aside_process_message {
  position: relative;
  padding-top: vw(325px);
  z-index: -1;
  @include mq {
    padding-top: pc(230px);
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: vw(443px);
    aspect-ratio: 443 / 513;
    background-image: url(/images/mv_static.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    top: vw(-200px);
    right: vw(-78px);
    @include mq {
      top: unset;
      width: pc(1062px);
      top: pc(-500px);
      right: unset;
      left: 50%;
      translate: pc(-250px) 0;
    }
    @include mq(pc) {
      top: -500px;
      width: 1062px;
      translate: -250px 0;

    }
    
  }
  .inner {
    @include container;
    h2 {
      font-size: vw(--fs-xxl);
      font-weight: var(--bold);
      line-height: var(--line-height-xsmall);
      @include mq {
        font-size: pc(65px);
        letter-spacing: .05em;
      }
    }
    p {
      margin-top: vw(32px);
      font-size: vw(--fs-s);
      line-height: var(--line-height-large);
      font-weight: var(--bold);
      @include mq {
        margin-top: pc(60px);
        font-size: pc(--fs-l);
        letter-spacing: .1em;
        translate: .25em 0;
      }
    }
  }
}
.m_taglist {
  @include module;
  @include container;
  .inner {
    background-color: var(--color-surface);
    padding: vw(30px) vw(16px);
    border-radius: var(--border-radius);
    @include mq {
      padding: pc(42px) pc(48px);
    }
    h3 {
      color: var(--color-third-weak);
      font-weight: var(--bold);
      font-size: vw(--fs-s);
      font-family: var(--en);
      text-transform: uppercase;
      @include mq {
        font-size: pc(--fs-l);
      }
    }
    ul {
      margin-top: vw(18px);
      display: flex;
      flex-wrap: wrap;
      gap: vw(8px) vw(8px);
      @include mq {
        margin-top: pc(18px);
        gap: pc(16px) pc(16px);
      }
      li {
        a, span {
          @include hashtag(large);
        }
      }
    }
  }
}
.aside_pickup {
  .inner {
    .aside_pickup_inner {
      @include module;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, auto);
      grid-gap: vw(60px) 0;
      @include mq {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: pc(55px) pc(55px);
      }
      .unit {
        .photo {
          @include mq {
            display: flex;
            align-items: center;
          }
          a {
            @include mq {
              @include hoverOp;
            }
          }
          img {
            border-radius: var(--border-radius-small);
            @include mq {
              border-radius: var(--border-radius);
            }
          }
        }
        .title {
          margin-top: vw(32px);
          @include mq {
            margin-top: pc(42px);
          }
          h3 {
            font-family: var(--en);
            font-size: vw(--fs-l);
            color: var(--color-third-weak);
            font-weight: var(--bold);
            text-transform: uppercase;
            @include mq {
              font-size: pc(--fs-xl);
            }
            a {
              text-decoration: none;
              color: var(--color-third-weak);
              display: grid;
              grid-template-columns: auto auto;
              gap: 1em;
              align-items: center;
              justify-content: space-between;
              @include hoverColor(var(--color-brand));
              @media (any-hover: hover) {
                &:hover {
                  &::after {
                    translate: -.3em 0;
                  }
                }
              }
              &::after {
                content: '';
                display: block;
                background-image: url(/images/carousel.svg);
                background-size: contain;
                background-repeat: no-repeat;
                width: vw(38px);
                aspect-ratio: 38 / 26;
                transition: translate var(--transition-speed) var(--bezier);
                @include mq {
                  width: pc(50px);
                }
              }
            }
          }
        }
        .desc {
          font-family: var(--en);
          margin-top: vw(16px);
          font-size: vw(--fs-s);
          line-height: var(--line-height-medium);
          @include mq {
            margin-top: pc(26px);
            font-size: pc(--fs-l);
          }
        }
      }
    }
  }
}
.nav_cases {
  @include module;
  .inner {
    @include container;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: vw(6px);
    @include mq() {
      grid-template-columns: repeat(3, 1fr);
      gap: pc(30px);
    }
    .unit {
      border-radius: var(--border-radius-small);
      border: 1px solid #E7E9EA;
      background-color:var(--color-surface);
      transition: border-color .3s cubic-bezier(0,.88,.81,.99);
      padding: vw(10px) vw(10px) vw(12px);
      display: grid;
      grid-template-rows: subgrid;
      grid-row: span 3;
      gap: 0;
      @include mq() {
        border-radius: var(--border-radius);
        padding: pc(18px) pc(18px) pc(24px);
      }
      .image {
        position: relative;
        filter: drop-shadow(0 8px 18px rgba(0, 0, 0, 0.06));
        video {
          object-fit: cover;
          width: vw(146px);
          height: vw(100px);
          aspect-ratio: 146 / 100;
          @include mq() {
            width: pc(245px);
            height: pc(167px);
          }
        }
        picture, video {
          display: block;
          mask-image: url('/images/mask_01.svg');
          mask-repeat: no-repeat;
          mask-size: contain;
          will-change: scale, opacity;
          transition: opacity .5s var(--bezier), scale .5s cubic-bezier(0,.88,.81,.99);
          source, img {
            transition: scale .5s cubic-bezier(0,.88,.81,.99);
          }
          img {
            object-fit: cover;
            width: vw(146px);
            height: vw(100px);
            aspect-ratio: 146 / 100;
            @include mq() {
              width: pc(245px);
              height: pc(167px);
            }
          }
        }
        ul {
          position: absolute;
          right: vw(-10px);
          bottom: vw(10px);
          display: flex;
          gap: vw(3px);
          flex-direction: column;
          align-items: flex-start;
          @include mq() {
            right: pc(-15px);
            bottom: pc(10px);
            gap: pc(4px);
          }
          li {
            rotate: z -13deg;
            background-color: var(--color-second);
            border-radius: var(--border-radius-pill);
            font-weight: var(--bold);
            font-size: vw(5px);
            padding: vw(3px) vw(6px) vw(4px);
            display: inline grid;
            grid-template-columns: auto 1fr;
            gap: vw(3px);
            $i: 1;
            transition: scale .3s cubic-bezier(.17,.89,.6,1.18), opacity .3s cubic-bezier(.17,.89,.6,1.18);
            @include mq() {
              font-size: pc(--fs-xxxs);
              padding: pc(5px) pc(10px) pc(6px);
              gap: pc(3px);
            }
            &::before {
              content: '#';
              font-family: var(--en);
              font-weight: var(--medium);
            }

          }
        }
      }
      .text {
        margin-top: vw(16px);
        @include mq() {
          margin-top: pc(24px);
        }
        h2 {
          font-weight: var(--bold);
          font-size: vw(--fs-xxs);
          line-height: var(--line-height-xsmall);
          padding-inline: vw(4px);
          font-family: var(--en);
          @include mq() {
            font-size: pc(--fs-l);
            padding-inline: pc(4px);
          }
        }
      }
      .partner {
        padding-top: vw(18px);
        align-self: end;
        @include mq() {
          padding-top: pc(24px);
        }
        dt {
          text-align: right;
          img {
            max-height: vw(25px);
            max-width: vw(75px);
            @include mq() {
              max-height: pc(50px);
              max-width: pc(125px);
            }
          }
        }
      }
    }
  }
}
.aside_process {
  position: relative;
  overflow: hidden;
  padding-top: vw(340px);
  @include mq() {
    padding-top: pc(140px);
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: vw(443px);
    aspect-ratio: 443 / 513;
    background-image: url(/images/mv_static.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    top: vw(-200px);
    right: vw(-78px);
    @include mq {
      top: unset;
      width: pc(1062px);
      top: pc(-500px);
      right: unset;
      left: 50%;
      translate: pc(-250px) 0;
    }
    @include mq(pc) {
      top: -500px;
      width: 1062px;
      translate: -150px 0;

    }
    
  }
  .inner {
    .aside_process_inner {
      @include module;
      p {
        font-size: vw(--fs-m);
        line-height: var(--line-height-large);
        @include mq() {
          font-size: pc(--fs-l);
        }
      }
      .button {
        @include parts;
        a {
          @include button(small);
          margin-left: auto;
          @include mq() {
            margin-left: unset;
          }
        }
      }
    }
  }
}