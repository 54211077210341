@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_button {
  @include module;
  .inner {
    @include container;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        text-align: center;
        margin: 0 vw(8px) vw(20px);
        @include mq {
          margin: 0 pc(12px) pc(26px);
        }
        a {
          @include button($size:small);
        }
      }
    }
  }
  &.cv {
    .inner {
      ul {
        li {
          a {
            @include button($size:apply);
          }
        }
      }
    }
  }
}
