@charset "UTF-8";
@import "reset";
@import "mixins";

:root {

  /* COLOR */
  --color-brand: #9F7A4E;
  --color-brand-weak: #DECEBD;
  --color-brand-weakest: ##DECEBD;

  --color-second: #FFE940;
  --color-second-weak: #FFE940;
  --color-second-weakest: #FFE940;

  --color-third: #295171;
  --color-third-weak: #447CA9;
  --color-third-weakest: #7EAFD7;
  --color-third-surface: #C8DCED;

  --color-surface: #ffffff;

  --color-black: #262626;

  --color-text: var(--color-black);
  --color-text-on-accent: #ffffff;


  --color-border: #DBDBDB;

  --color-bg: #F4F4F4;
  --color-bg-table: #ebebeb;

  /* FONT */
  --font-feature-settings: "palt" 1,;

  --gothic: YakuHanJP, noto-sans-cjk-jp, sans-serif;
  --en: tt-travels-next, YakuHanJP, noto-sans-cjk-jp, sans-serif;


  /* LINE HEIGHT */
  --line-height-xxsmall:120%;
  --line-height-xsmall:140%;
  --line-height-small:160%;
  --line-height-medium:180%;
  --line-height-large:200%;

  --letter-spacing-medium: 0.05em;

  --padding-sp: 25;

  /* WIDTH */
  --width-container: 1440px;
  --width-container-wide: 1440px;

  --margin-section: 120;
  --margin-section-pc: 180;
  --margin-module: 35;
  --margin-module-pc: 70;
  --margin-parts: 20;
  --margin-parts-pc: 30;

  /* TRANSITION */
  --bezier: cubic-bezier(.25,.1,.25,1);
  --bezier-b: cubic-bezier(.06,.68,.08,.99);
  --transition-speed: .3s;
  --transition-speed-fast: .1s;
  --transition-speed-slow: .5s;

  --medium: 500;
  --demi: 600;
  --bold: 700;
  --extrabold: 800;

  --fs-xxxxs: 9;
  --fs-xxxs: 10;
  --fs-xxs: 11;
  --fs-xs: 12;
  --fs-s: 14;
  --fs-m: 16;
  --fs-l: 18;
  --fs-xl: 24;
  --fs-xxl: 32;
  --fs-xxxl: 40;
  --fs-xxxxl: 48;

  --border-radius-pill: 999px;
  --border-radius-circle: 50%;

  --border-radius-small: 10px;
  --border-radius: 20px;
  --border-radius-large: 50px;
  --border-radius-xlarge: 100px;
  
  --gap-2col-row-pc: 30px;

}

* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
html {
  font-size: vw(--fs-s);
  height: 100%;
  text-size-adjust: 100%;
  @include mq() {
    font-size: pc(--fs-m);
  }
}
body {
  color: var(--color-text);
  background-color: var(--color-bg);
  font-feature-settings: var(--font-feature-settings);
  font-family: var(--gothic);
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: normal;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-size: vw(--fs-m);
  @include mq() {
    font-size: pc(--fs-l);
  }
  &.is-fixed, &.fixed {
    overflow: hidden;
  }
}

strong {
  font-weight: var(--bold);
}
label {
  cursor: pointer;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  &[href^="tel:"] {
    color: inherit;
  }
}
abbr {
  text-decoration: none;
}
button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  color: inherit;
  text-align: inherit;
}
br {
  &.sp-only {
    @include mq() {
      display: none;
    }
  }
  &.pc-only {
    display: none;
    @include mq() {
      display: inline;
    }
  }
}
:where(button, [type='button'], [type='reset'], [type='submit']) {
  touch-action: manipulation;
}
:where(
    :any-link,
    button,
    [type='button'],
    [type='reset'],
    [type='submit'],
    label[for],
    select,
    summary,
    [role='tab'],
    [role='button']
  ) {
  cursor: pointer;
}
:focus:not(:focus-visible) {
  outline: none;
}
sup,
sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  position: relative;
  font-size: 0.75em;
}
sup {
  bottom: .75ex;
}
sub {
  top: .5ex;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
.svg_def {
  display: none;
}
svg {
  height: auto;
}
pre {
  white-space: pre-wrap;
}
html.lenis,
html.lenis body {
  height: auto;
}
.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}
.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}
.lenis.lenis-stopped {
  overflow: hidden;
}
.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
html.is-changing .transition-fade {
  transition: opacity .5s;
  opacity: 1;
}

html.is-animating .transition-fade {
  transition: opacity .5s;
  opacity: 0;
}
.hidden-sp {
  display: none;
  @include mq {
    display: unset;
  }
}
.hidden-pc{
  @include mq {
    display: none;
  }
}
.hidden-over-tbl {
  @include mq(tbl) {
    display: none;
  }
}
.hidden-over-tb {
  @include mq(tb) {
    display: none;
  }
}
.hidden-over-sp {
  @include mq(sp) {
    display: none;
  }
}
main {
  overflow:clip;
  /*
  @include mq() {
    &:has(.leftArea) {
      display: grid;
      grid-template:
        "left content" auto
        / auto pc(745px);
      width: pc(1143px);
      margin-inline: auto;
      .leftArea {
        grid-area: left;
      }
      .contentArea {
        grid-area: content;
      }
    }
  }
    */
}

.fadeUp {
  opacity: 0;
  translate: 0 vw(30px);
  transition:
    opacity cubic-bezier(0,1.42,.98,1.09) .5s,
    translate cubic-bezier(0,1.42,.98,1.09) .5s;
  @include mq() {
    translate: 0 pc(30px);
  }
  &.is-active {
    opacity: 1;
    translate: 0 0;
  }
  &.delay-pc {
    @include mq() {
      transition-delay: .5s;
    }
  }
}

#fixedFooterButton {
  position: fixed;
  bottom: 0;
  width: 100%;
  opacity: 0;
  transition: opacity .5s $bezier;
  pointer-events: none;
  text-align: center;
  padding: vw(15px);
  z-index: 100;
  &.is-scroll {
    opacity: 1;
    pointer-events: all;
  }
  @include mq(tbl) {
    padding: pc(35px) 0;
  }
  a {
    @include button(apply);
    background-color: #1982D8;
    filter:drop-shadow(0px 10px 16px rgba(25,130,216,.21));
    padding: vw(22px) vw(50px);
    border-radius: var(--border-radius-pill);
    font-size: vw(--fs-xs);
    width: auto;
    min-width: 0;
    display: block;
    margin-inline: auto;
    &::before {
      content: none;
    }
    @include mq() {
      font-size: pc(--fs-m);
      padding: pc(30px) pc(60px) pc(27px);
      min-width: 0;
      width: pc(615px);
    }
    strong {
      font-size: vw(--fs-m);
      @include mq() {
        font-size: pc(--fs-xl);
      }
    }
    &:hover {
      @include mq() {
        background-color: var(--color-third-weakest);
        transform: translateY(-3px);
      }
    }
  }
}
//default
@import "header";
@import "footer";
@import "modules";
@import "component/index";
@import "navigation/index";
@import "articlewrapper";
@import "top";
@import "swiper";

