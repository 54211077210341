@charset "UTF-8";

@import "mixins";

.component.type_catchcopy_image_bg {
  @include section;
  @include mq {
    @include container;
  }
  .inner {
    @include mq {
      position: relative;
    }
    .image {
      width: 92%;
      @include mq {
        width: pc(1000px);
      }
      img {
        object-fit: cover;
        border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
        @include mq {
          border-radius: var(--border-radius);
        }
      }
    }
    .text {
      background-color: var(--color-surface);
      padding: vw(24px);
      width: 85%;
      max-width: vw(400px);
      position: relative;
      margin-top: vw(-15px);
      margin-left: auto;
      border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
      @include mq {
        padding: pc(48px);
        margin-top: unset;
        position: absolute;
        right: 0;
        bottom: 0;
        width: pc(380px);
        border-radius: var(--border-radius);
      }
      h3 {
        @include heading-small;
      }
      .copy {
        h4 {
          margin-top: vw(12px);
          font-size: vw(--fs-xl);
          font-weight: var(--bold);
          line-height: var(--line-height-xsmall);
          @include mq {
            margin-top: pc(32px);
            font-size: pc(--fs-xxl);
          }
        }
      }
      .desc {
        margin-top: vw(12px);
        @include mq {
          margin-top: pc(12px);
        }
        p {
          font-size: vw(--fs-xs) !important;
          line-height: var(--line-height-xsmall) !important;
          @include mq {
            font-size: pc(--fs-m) !important;
            line-height: var(--line-height-small) !important;
          }

        }
      }
      .button {
        margin-top: 24px;
        text-align: left;
        @include mq {
          margin-top: 32px;
        }
        a {
          text-align: center;
          @include button($size: small);
        }
      }
    }
  }
  &.reverse {
    .inner {
      .image {
        margin-left: auto;
        img {
          border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
          @include mq {
            border-radius: var(--border-radius);
          }
        }
      }
      .text {
        margin-left: 0;
        margin-right: auto;
        border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
        @include mq {
          border-radius: var(--border-radius);
          right: auto;
          left: 0;
        }
      }
    }
  }
}
