@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_speaker {
  @include module;
  .inner {
    @include container;
    @include mq {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: flex-start;
      gap: 5%;
    }
    .speaker {
      @include mq {
        width: 30%;
        margin-top: pc(5px);
        position: sticky;
        top: pc(--margin-module-pc);
        &.static {
          position: static;
        }
      }
      .heading {
        font-weight: var(--bold);
        font-size: vw(--fs-s);
        color: var(--color-third-weak);
        @include mq {
          font-size: pc(--fs-m);
        }
      }
      .unit {
        margin-top: vw(15px);
        @include mq {
          margin-top: pc(15px);
        }
        + .unit {
          margin-top: vw(30px);
          @include mq {
            margin-top: pc(35px);
          }
        }
        .photo {
          img {
          
            border-radius: var(--border-radius-small);
          }
        }
        .position {
          font-size: vw(12px);
          padding-bottom: vw(4px);
          text-align: center;
          @include mq {
            font-size: pc(14px);
            padding-bottom: pc(4px);
            
          }
        }
        .name {
          margin-top: vw(24px);
          @include mq {
            margin-top: pc(36px);
          }
          dt {
            font-weight: var(--bold);
            font-size: vw(--fs-l);
            @include mq {
              font-size: pc(--fs-xl);
            }
            &:last-child {
              margin-bottom: unset;
            }
          }
          dd {
            padding-top: vw(8px);
            font-size: vw(--fs-s);
            font-weight: var(--demi);
            @include mq {
              padding-top: pc(8px);
              font-size: pc(--fs-s);
            }
          }
        }
        .profile {
          margin-top: vw(16px);
          font-size: vw(--fs-s);
          line-height: var(--line-height-medium);
          @include mq {
            margin-top: pc(24px);
            font-size: pc(--fs-m);
          }
          &.en {
            font-size: vw(--fs-xs);
            @include mq {
              font-size: pc(--fs-xs);
            }
          }
        }
      }
    }
    .text {
      margin-top: vw(40px);
      @include mq {
        width: 65%;
        margin-top: unset;
      }
      .container, .inner {
        width: 100% !important;
      }
      .float_image {
        @include clearfix;
        padding-bottom: 2em;
        figure {
          float: right;
          width: 52%;
          padding-left: 4%;
          padding-top: vw(8px);
          padding-bottom: vw(10px);
          @include mq {
            padding-top: pc(8px);
            padding-bottom: pc(10px);
          }
          figcaption {
            margin-top: vw(5px);
            text-align: center;
            @include mq {
              margin-top: pc(5px);
            }
          }
        }
      }
      .component {
        margin-top: vw(15px);
        padding-bottom: vw(30px);
        @include mq {
          margin-top: pc(20px);
          padding-bottom: pc(40px);
        }
        .container {
          padding: 0;
          display: block;
          .inner {
            .text {
              margin-top: 0;
              width: 100%;
            }
          }
        }
      }
      .type_movie {
        margin-top: vw(20px);
        @include mq {
          margin-top: pc(20px);
          padding-bottom: pc(40px);
        }
        .container {
          padding: 0;
          display: block;
        }
      }
      .type_column,
      .type_sub {
        margin-top: vw(15px);
        padding-bottom: vw(20px);
        @include mq {
          margin-top: pc(20px);
          padding-bottom: pc(48px);
        }
        .container {
          padding: 0;
          display: block;
          .inner {
            .text {
              margin-top: 0;
              width: 100%;
            }
          }
        }
      }
      .type_table {
        padding-left: 0;
        padding-right: 0;
        width: auto;
        .inner {
          padding: 0;
          display: block;
          .table_set {
            table {
              th,td {
                font-size: vw(--fs-s);
                padding: vw(8px) vw(10px);
                
                @include mq {
                  font-size: pc(--fs-m);
                  padding: pc(8px) pc(10px);
                  
                }
              }
            }
            &.compact {
              table {
                th,td {
                  font-size: vw(--fs-s);
                  padding: vw(4px) vw(5px);
                  @include mq {
                    font-size: pc(--fs-m);
                    padding: pc(4px) pc(5px);
                  }

                }
              }
            }
          }
        }
      }
    }
  }
}
