@charset "UTF-8";
@import "variables";
@import "mixins";

.component {
  font-family: var(--en);
  strong, b {
    font-weight: var(--bold);
  }
  a {
    color: var(--color-third-weak);
    text-decoration: underline;
  }
  .text {
    p {
      padding-bottom: 2em;
      font-family: var(--gothic);
      font-size: vw(--fs-m);
      line-height: var(--line-height-large);
      @include mq() {
        font-size: pc(--fs-l);
      }
      &.note {
        padding-bottom: 0;
        font-size: vw(--fs-s);
        @include mq() {
          font-size: pc(--fs-m);
        }
      }
      &.heading {
        @include heading-small;
        padding-bottom: 1em;
        font-family: var(--en) !important;
      }
      &.figure {
        padding-bottom: 35px;
        @include section;
        padding-bottom: 0;
        @include mq {
          padding-bottom: 65px;
        }
        img {
          &.mt-image-left,
          &.mt-image-center,
          &.mt-image-right,
          &.mt-image-none {
            margin-bottom: vw(6px) !important;
            display: block;
            margin-left: auto;
            margin-right: auto;
            border-radius: var(--border-radius-small);
            @include mq {
              margin-bottom: pc(12px) !important;
            }
          }
        }
      }
      img {
        &.mt-image-left,
        &.mt-image-center,
        &.mt-image-right {
          margin-bottom: 0 !important;
        }
      }
      &:last-child {
        padding-bottom: 0;
      }
      & + .button{
        margin-top: 0;
      }
      .en {
        font-family: var(--en);
      }
    }
    ul, ol {
      padding-bottom: 2em;
      font-family: var(--gothic);
      font-size: vw(--fs-m);
      line-height: var(--line-height-large);
      @include mq {
        font-size: pc(--fs-l);
      }
      &:last-child {
        padding-bottom: 0;
      }
      .en {
        font-family: var(--en);
      }
    }
    ul {
      list-style: disc;
      li {
        margin-left: vw(20px);
        @include mq {
          margin-left: pc(20px);
        }
      }
    }
    ol {
      list-style: decimal;
      font-family: var(--gothic);
      li {
        margin-left: vw(20px);
        @include mq {
          margin-left: pc(20px);
        }
      }
    }
    .button {
      margin-top: vw(15px);
      text-align: right;
      @include mq {
        margin-top: pc(20px);
      }
      a {
        @include button;
        text-align: center;
      }
    }
  }
  .image, .movie, .map {
    text-align: center;
    &.shrink {
      margin-left: auto;
      margin-right: auto;
    }
    img {
      border-radius: var(--border-radius-small);
    }
    a {
      @include mq {
        @include hoverOp;
      }
    }
    .caption {
      text-align: center;
      margin-top: vw(8px);
      font-size: vw(--fs-xs);
      line-height: var(--line-height-small);
      font-weight: var(--bold);
      @include mq {
        margin-top: pc(16px);
        font-size: pc(--fs-s);
      }
      &.left {
        text-align: left;
      }
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
    }
    .child {
      padding-bottom: vw(15px);
      @include mq {
        padding-bottom: pc(35px);
      }
      &:last-child {
        padding-bottom: unset;
      }
    }
  }
}

@import "pagetitle";
@import "extendedtitle";
@import "catchcopy";
@import "catchcopy_image";
@import "catchcopy_image_bg";
@import "conversion";
@import "blogtitle";
@import "heading";
@import "heading_ex";
@import "text";
@import "text_image";
@import "text_map";
@import "map";
@import "image";
@import "image_multi";
@import "movie";
@import "movie_multi";
@import "panel";
@import "speaker";
@import "column";
@import "table";
@import "button";
@import "anchor";
@import "num";
@import "wysiwyg";
@import "catalog";
@import "faq";
@import "member";
@import "iframe";
@import "pagelist";
