@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_text_map {
  @include module;
  .inner {
    @include container;
    @include mq {
      display: flex;
      justify-content: space-between;
    }
    .text {
      margin-top: vw(30px);
      @include mq {
        width: 47%;
        margin-top: unset;
      }
      p.button {
        a {
          @include button(small);
        }
      }
    }
    .map {
      @include mq {
        width: 47%;
        margin-top: unset;
        padding-top: pc(10px);
      }
      .map_wrap_outer {
        width: 100%;
        &.shrink {
          margin-left: auto;
          margin-right: auto;
        }
        .map_wrap_inner {
          padding-top: 56.25%;
          position: relative;
          display: block;
          width: 100%;
          iframe {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            border-radius: var(--border-radius-small);
          }
        }
      }
    }
  }
  &.reverse {
    .inner {
      @include mq {
        flex-direction: row-reverse;
      }
    }
  }
}
