@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_image_multi {
  @include module;
  &.set1 {
    .inner {
      @include mq {
        justify-content: center;
      }
    }
  }
  &.set2 {
    .inner {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, auto);
      grid-gap: vw(32px) 0;
      @include mq {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: pc(64px) pc(32px);
      }
    }
  }
  &.set3 {
    .inner {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, auto);
      grid-gap: vw(32px) 0;
      @include mq {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: pc(64px) pc(32px);
      }
    }
  }
  &.set4 {
    .inner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, auto);
      grid-gap: vw(32px) vw(16px);
      @include mq {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: pc(48px) pc(24px);
      }
    }
  }
  .inner {
    @include container;
    .image {
      &.shrink {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
