@charset "UTF-8";
@import "mixins";

#intro {
  position: fixed;
  inset: 0;
  background-color: var(--color-surface);
  z-index: 91;
  @include mq {
    display: grid;
    justify-content: center;
    padding-block: pc(120px) pc(120px);
  }
  &.is-finished {
    background-color: transparent;
    position: absolute;
    pointer-events: none;
    .inner {
      overflow: hidden;
      .lead {
        opacity: 0;
      }
      .tagline {
        h1 {
          p {
            .parent {
              .child {
                i {
                  translate: 0 0;
                  opacity: 1;
                }
              }
            }
          }
        }
        .p_button {
          translate: 0 0;
          opacity: 1;
          height: auto;
        }
      }
      .illust {
        opacity: 0 !important;
        scale: 1.5;
        @include mq {
          translate: 0 0;
          scale: 1.1;
        }
      }

    }
    + #floats {
      .inner {
        img {
          scale: 1;
          opacity: 1;
  
        }

      }
    }
  }
  &.is-ready {
    .inner {
      opacity: 1;
      .lead {
        .leadinner {
          .top, .middle, .bottom {
            // translate: 0 0;
            // opacity: 1;
            .parent {
              .child {
                i {
                  translate: 0 0;
                  opacity: 1;
                }
              }
            }

          }

        }
      }
      .illust {
        opacity: 1;
        #lottie-introtop, #lottie-introbottom {
          translate: 0 0;
          opacity: 1;
        }
      }

    }
  }
  &.is-not-first {
    .inner {
      .tagline {
        h1 {
          .top, .bottom {
            transition-delay: .5s;
          }
        }
      }
      .illust {
        opacity: 0;
      }
    }
  }
  .inner {
    position: absolute;
    inset: 0;
    display: grid;
    grid-template: 
    "lead" 1fr
    "illust" auto
    / 1fr;
    opacity: 0;
    @include mq {
      width: pc(1080px);
      margin-inline: auto;
      position: static;
      grid-template: 
      "lead illust" 1fr
      / 1fr 1fr;
      row-gap: 0;
      align-items: center;
    }
    .lead {
      padding: vw(30px) vw(32px) 0;
      font-size: vw(--fs-s);
      font-weight: var(--bold);
      line-height: var(--line-height-large);
      grid-area: lead;
      text-align: center;
      align-self: center;
      @include mq {
        text-align: left;
        padding: unset;
        align-self: end;
        font-size: pc(22px);
        padding-bottom: pc(30px);
      }
      .leadinner {
        .heading {
          display: none;
          @include mq() {
            display: block;
            font-weight: var(--bold);
            font-size: pc(52px);
            margin-bottom: pc(64px);
          }
          p {
            display: block;
            line-height: 130%;
            .parent {
              .child {
                display: flex;
                i {
                  will-change: transform, opacity;
                  backface-visibility: hidden;
                  transition: translate .8s cubic-bezier(.17,.89,.6,1.18), opacity .8s cubic-bezier(.17,.89,.6,1.18);
                  translate: 0 vw(10px);
                  opacity: 0;
                  letter-spacing: .05em;
                  $i: 1;
                  @while $i < 20 {
                    &:nth-child(#{$i}) {
                      transition-delay: ($i * .06) + s;
                    }
                    $i: $i + 1;
                  }
                  @include mq {
                    translate: 0 pc(20px);
                  }
                }
              }
            }
          }
        }
        div {
          &.middle {
            transition-delay: 3s;
            .parent {
              .child {
                i {
                  $i: 1;
                  @while $i < 20 {
                    &:nth-child(#{$i}) {
                      transition-delay: ($i * .05) + 3s;
                    }
                    $i: $i + 1;
                  }
                }
              }
            }
          }
          &.bottom {
            transition-delay: 6s;
            .parent {
              .child {
                i {
                  $i: 1;
                  @while $i < 20 {
                    &:nth-child(#{$i}) {
                      transition-delay: ($i * .05) + 6s;
                    }
                    $i: $i + 1;
                  }
                }
              }
            }
          }
          .parent {
            .child {
              display: inline flex;
              i {
                will-change: transform, opacity;
                backface-visibility: hidden;
                transition: translate .5s cubic-bezier(.17,.89,.6,1.18), opacity .5s cubic-bezier(.17,.89,.6,1.18);
                translate: 0 vw(5px);
                opacity: 0;
                letter-spacing: .05em;
                $i: 1;
                @while $i < 20 {
                  &:nth-child(#{$i}) {
                    transition-delay: ($i * .05) + s;
                  }
                  $i: $i + 1;
                }
                @include mq {
                  translate: 0 pc(6px);
                }
              }
            }
          }
        }
      } 
    }
    .tagline {
      position: absolute;
      padding-inline: vw(32px);
      top: vw(350px);
      @include mq() {
        top: pc(180px);
        width: auto;
        padding: unset;
        align-self: self-start;
      }
      h1 {
        font-weight: var(--bold);
        font-size: vw(--fs-xxl);
        @include mq() {
          font-size: pc(64px);
        }
        p {
          display: block;
          line-height: 130%;
          .parent {
            .child {
              display: flex;
              i {
                will-change: transform, opacity;
                backface-visibility: hidden;
                transition: translate .8s cubic-bezier(.17,.89,.6,1.18), opacity .8s cubic-bezier(.17,.89,.6,1.18);
                translate: 0 vw(10px);
                opacity: 0;
                letter-spacing: .05em;
                $i: 1;
                @while $i < 20 {
                  &:nth-child(#{$i}) {
                    transition-delay: ($i * .06) + s;
                  }
                  $i: $i + 1;
                }
                @include mq {
                  translate: 0 pc(20px);
                }
              }
            }
          }
        }
      }
      .p_button {
        margin-top: vw(24px);
        translate: 0 vw(20px);
        opacity: 0;
        height: 0;
        transition: translate 1s cubic-bezier(.17,.89,.6,1.18) .5s, opacity 1s cubic-bezier(.17,.89,.6,1.18) .5s;
        pointer-events: all;
        @include mq() {
          margin-top: pc(90px);
          translate: 0 pc(20px);
        }
      }
  
    }
    .illust {
      grid-area: illust;
      padding: vw(40px) vw(30px);
      transition: scale .6s ease, opacity .6s cubic-bezier(.17,.89,.6,1.18);
      transform-origin: bottom center;
      opacity: 0;
      @include mq {
        padding: unset;
        align-self: self-end;
        transition: translate 0.5s cubic-bezier(.17,.89,.6,1.18), opacity .5s cubic-bezier(.17,.89,.6,1.18), scale .5s cubic-bezier(.17,.89,.6,1.18);
      }
      div {
        translate: 0 vw(30px);
        opacity: 0;
        transition: translate .5s cubic-bezier(.17,.89,.6,1.18) .5s, opacity .5s cubic-bezier(.17,.89,.6,1.18) .5s;
        @include mq {
          translate: 0 pc(30px);
        }
      }
      #lottie-introtop {
        svg {
          @include mq {
            height: calc((100vh - pc(290px)) * .4) !important;
            max-height: 348px;
          }
        }
      }
      #lottie-introbottom {
        svg {
          @include mq {
            height: calc((100vh - pc(290px)) * .6) !important;
            max-height: 433px;
          }
        }
      }
    }
  }
}
#floats {
  overflow: hidden;
  position: relative;
  left: 0;
  z-index: 2;
  @keyframes float {
    0% {
      translate: 0 0;
    }
    50% {
      translate: 0 vw(-20px);
      @include mq() {
        translate: 0 pc(-20px);
      }
    }
    100% {
      translate: 0 0;
    }
  }
  @keyframes floatPC {
    0% {
      translate: 0 0;
    }
    50% {
      translate: 0 pc(-20px);
    }
    100% {
      translate: 0 0;
    }
  }
  @keyframes floatShort {
    0% {
      translate: 0 0;
    }
    50% {
      translate: 0 vw(10px);
      @include mq() {
        translate: 0 pc(10px);
      }
    }
    100% {
      translate: 0 0;
    }
  }
  @keyframes floatShortPC {
    0% {
      translate: 0 0;
    }
    50% {
      translate: 0 pc(10px);
    }
    100% {
      translate: 0 0;
    }
  }
  .inner {
    width: vw(443px);
    height: vw(533px);
    overflow: hidden;
    position: relative;
    margin-top: vw(-160px);
    margin-left: auto;
    @include mq() {
      width: pc(1049px);
      height: pc(1230px);
      margin-top: unset;
      top: pcmax(-456px);
      right: pcmax(-100px);
    }
    @include mq(pc) {
      width: 1049px;
      height: 1230px;
      top: -456px;
      left: calc((50vw - 800px) * -1);
    }
    img {
      position: absolute;
      animation: float 8s ease-in-out infinite;
      transition: scale 1.5s cubic-bezier(0,.93,.15,1), opacity 1.5s cubic-bezier(0,.93,.15,1);
      opacity: 0;
      scale: 0;
      @include mq() {
        animation-name: floatPC;
      }
      &.book {
        width: vw(65px);
        top: vw(144px);
        left: vw(240px);
        animation-name: floatShort; 
        animation-duration: 6s;
        @include mq() {
          width: pc(155px);
          top: pc(389px);
          left: pc(569px);
          animation-name: floatShortPC; 
        }
      }
      &.box_01 {
        width: vw(77px);
        top: vw(29px);
        left: vw(26px);
        animation-name: floatShort;
        animation-duration: 4s;
        @include mq() {
          width: pc(182px);
          top: pc(68px);
          left: pc(61px);
          animation-name: floatShortPC; 
        }
      }
      &.box_02 {
        width: vw(77px);
        top: vw(357px);
        left: vw(364px);
        animation-name: floatShort;
        animation-duration: 6s;
        @include mq() {
          width: pc(182px);
          top: pc(844px);
          left: pc(861px);
          animation-name: floatShortPC; 
        }
      }
      &.earth {
        width: vw(58px);
        top: vw(245px);
        left: vw(336px);
        animation-name: floatShort;
        animation-duration: 8s;
        @include mq() {
          width: pc(138px);
          top: pc(580px);
          left: pc(796px);
          animation-name: floatShortPC; 
        }
      }
      &.house {
        width: vw(65px);
        top: vw(102px);
        left: vw(135px);
        animation-name: floatShort;
        animation-duration: 5s;
        @include mq() {
          width: pc(153px);
          top: pc(241px);
          left: pc(321px);
          animation-name: floatShortPC; 
        }
      }
      &.pc {
        width: vw(64px);
        top: vw(393px);
        left: vw(117px);
        rotate: z 4.483deg;
        animation-name: floatShort;
        animation-duration: 7s;
        @include mq() {
          width: pc(151px);
          top: pc(931px);
          left: pc(277px);
          animation-name: floatShortPC; 
        }
      }
      &.phone {
        width: vw(57px);
        top: vw(443px);
        left: vw(264px);
        rotate: z 5.759deg;
        animation-name: floatShort;
        animation-duration: 8s;
        @include mq() {
          width: pc(136px);
          top: pc(1049px);
          left: pc(626px);
          animation-name: floatShortPC; 
        }
      }
      &.saturn {
        width: vw(79px);
        top: vw(279px);
        left: vw(96px);
        animation-name: floatShort;
        animation-duration: 10s;
        @include mq() {
          width: pc(186px);
          top: pc(661px);
          left: pc(228px);
          animation-name: floatShortPC; 
        }
      }
      &.nendo_a {
        width: vw(114px);
        top: vw(121px);
        left: 0;
        animation-duration: 8s;
        @include mq() {
          width: pc(247px);
          top: pc(288px);
        }
      }
      &.nendo_b {
        width: vw(90px);
        top: 0;
        left: vw(302px);
        animation-duration: 9s;
        @include mq() {
          width: pc(205px);
          left: pc(716px);
        }
      }
      &.nendo_c {
        width: vw(92px);
        top: vw(259px);
        left: vw(205px);
        animation-duration: 10s;
        @include mq() {
          width: pc(220px);
          top: pc(613px);
          left: pc(486px);
        }
      }
      &.dot_01 {
        width: vw(10px);
        top: vw(55px);
        left: vw(182px);
        animation-duration: 16s;
        @include mq() {
          width: pc(24px);
          top: pc(130px);
          left: pc(432px);
        }
      }
      &.dot_02 {
        width: vw(10px);
        top: vw(230px);
        left: vw(163px);
        animation-duration: 17s;
        @include mq() {
          width: pc(24px);
          top: pc(545px);
          left: pc(387px);
        }
      }
      &.dot_03 {
        width: vw(10px);
        top: vw(380px);
        left: vw(83px);
        animation-duration: 18s;
        @include mq() {
          width: pc(24px);
          top: pc(900px);
          left: pc(196px);
        }
      }
      &.dot_04 {
        width: vw(10px);
        top: vw(363px);
        left: vw(323px);
        animation-duration: 15s;
        @include mq() {
          width: pc(24px);
          top: pc(860px);
          left: pc(766px);
        }
      }
    }
  }

}
#intro_bg {
  position: absolute;
  top: 0;
  z-index: 1;
  height: vw(790px);
  width: 100%;
  background-image: url(/images/intro_bg.svg);
  background-size: cover;
  background-position: center bottom;
  @include mq() {
    height: pc(1187px);
    background-image: url(/images/intro_bg_pc.svg);
  }
}
.m_top_works {
  padding-top: vw(64px);
  overflow: hidden;
  position: relative;
  z-index: 2;
  @include mq() {
    padding-top: pc(120px);
  }
  .inner {
    padding-left: vw(15px);
    @include mq() {
      padding-left: unset;
    }
    .unit {
      display: block;
      margin-top: vw(56px);
      border-radius: vw(20px) 0 0 vw(20px);
      border: 1px solid #E7E9EA;
      border-right: none;
      background-color:var(--color-surface);
      transition: border-color .3s cubic-bezier(0,.88,.81,.99);
      @include mq() {
        margin-left: calc(50vw - pc(630px));
        border-radius: pc(20px) 0 0 pc(20px);
        margin-top: pc(90px);
      }
      @media (any-hover: hover) {
        &:hover {
          // border-color: var(--color-brand-weak);
          .unitinner {
            .image {
              picture, video {
                source, img {
                  scale: 1.1;
                }
              }
            }
          }
        }
      }
      &.is-active {
        .unitinner {
          .image {
            picture, video {
              scale: 1;
              opacity: 1;
            }
            ul {
              li {
                scale: 1.2;
                opacity: 1;
              }
            }
          }
        }
      }
      &:first-of-type {
        margin-top: unset;
      }
      .unitinner {
        padding: 0 vw(24px) vw(32px);
        @include mq() {
          padding: pc(80px) pc(64px) pc(46px) pc(96px);
          width: pc(1260px);
          display: grid;
          grid-template:
            "text image" 1fr
            "partner image" auto
            / 1fr auto;
          column-gap: pc(120px);
        }
        .image {
          margin-top: vw(-32px);
          position: relative;
          filter: drop-shadow(0 8px 18px rgba(0, 0, 0, 0.06));
          @include mq() {
            margin-top: pcmax(-142px);
            grid-area: image;
          }
          video {
            width: vw(330px);
            height: vw(225px);
            object-fit: cover;
            
            @include mq() {
              width: pc(380px);
              height: pc(553px);
            }

          }
          picture, video {
            display: block;
            mask-image: url('/images/mask_01.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
            will-change: scale, opacity;
            scale: 1.4;
            opacity: 0;
            transition: opacity .5s var(--bezier), scale .5s cubic-bezier(0,.88,.81,.99);
            @include mq() {
              mask-image: url('/images/mask_01_rotate.svg');
            }
            &.sp {
              @include mq(){
                display: none;
              }
            }
            &.pc {
              display: none;
              @include mq(){
                display: block;
              }
            }
            source, img {
              transition: scale .5s cubic-bezier(0,.88,.81,.99);
            }
            img {
              object-fit: cover;
              width: vw(330px);
              height: vw(225px);
              @include mq() {
                width: pc(380px);
                height: pc(553px);
              }
            }
          }
          ul {
            position: absolute;
            right: vw(-10px);
            bottom: vw(30px);
            display: flex;
            gap: vw(10px);
            flex-direction: column;
            align-items: flex-start;
            @include mq() {
              right: pc(-45px);
              bottom: pc(60px);
              gap: pc(16px);
            }
            li {
              rotate: z -13deg;
              background-color: var(--color-second);
              border-radius: var(--border-radius-pill);
              font-weight: var(--bold);
              font-size: vw(--fs-xxxxs);
              padding: vw(3px) vw(10px) vw(4px);
              display: inline grid;
              grid-template-columns: auto 1fr;
              gap: vw(3px);
              $i: 1;
              transition: scale .3s cubic-bezier(.17,.89,.6,1.18), opacity .3s cubic-bezier(.17,.89,.6,1.18);
              scale: 3;
              opacity: 0;
              @while $i < 10 {
                &:nth-child(#{$i}) {
                  transition-delay: $i / 8 + s;
                }
                $i: $i + 1;
              }
              @include mq() {
                font-size: pc(--fs-m);
                padding: pc(5px) pc(10px) pc(6px);
                gap: pc(3px);
              }
              &::before {
                content: '#';
                font-family: var(--en);
                font-weight: var(--medium);
              }
  
            }
          }
        }
        .text {
          margin-top: vw(24px);
          @include mq() {
            margin-top: unset;
            grid-area: text;
          }
          h2 {
            font-weight: var(--bold);
            font-size: vw(--fs-xl);
            line-height: var(--line-height-xsmall);
            margin-left: -.1em;
            @include mq() {
              font-size: pc(--fs-xxxxl);
            }
          }
          p {
            margin-top: vw(13px);
            font-size: vw(--fs-xs);
            line-height: var(--line-height-medium);
            @include mq() {
              font-size: pc(--fs-m);
              margin-top: pc(24px);
            }
          }
        }
        .partner {
          margin-top: vw(18px);
          display: flex;
          flex-direction: row-reverse;
          align-items: flex-end;
          gap: vw(12px);
          @include mq() {
            margin-top: unset;
            align-self: self-end;
            flex-direction: row;
            gap: pc(12px);
            padding-bottom: pc(30px);
          }
          dt {
            img {
              max-height: vw(50px);
              max-width: vw(150px);
              @include mq() {
                max-height: pc(60px);
                max-width: pc(190px);
              }
            }
  
          }
          dd {
            font-weight: var(--bold);
            font-size: vw(--fs-xxxs);
            line-height: var(--line-height-small);
            @include mq() {
              font-size: pc(--fs-s);
            }
          }
        }

      }
    }
    .p_button {
      margin-top: vw(42px);
      @include mq() {
        margin-top: pc(76px);
      }
      a {
        margin-inline: auto;
      }
    }
  }
}
#solutionsWrapper {
  overflow: hidden;

}
.m_top_solutions {
  margin-top: vw(-10px);
  @include mq() {
    margin-top: pc(42px);
  }
  .inner {
    padding-inline: vw(15px);
    @include mq() {
      @include container;
    }
    .unit {
      margin-top: vw(25px);
      border: 1px solid #E7E9EA;
      padding: vw(32px) vw(32px) vw(40px);
      background-color: var(--color-surface);
      border-radius: vw(10px);
      // width: vw(360px);
      @include mq() {
        margin-top: pc(56px);
        // width: pc(1440px);
        padding: pc(70px);
        border-radius: pc(20px);
        position: relative;
        display: grid;
        column-gap: pc(72px);
        grid-template:
          "image text ." auto
          / auto 1fr auto;
      }
      &:first-of-type {
        margin-top: unset;
      }
      .tab {
        display: none;
        @include mq {
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: fit-content;
          writing-mode: vertical-lr;
          border-left: 1px solid #E7E9EA;
          padding: pc(30px) pc(10px);
        }
        span {
          font-family: var(--en);
          font-weight: var(--medium);
          margin-bottom: 1em;

        }
        i {
          rotate: z 90deg;
          display: inline-block;
          &.vertical {
            rotate: z 0deg;
          }
        }
      }
      .image {
        border-radius: vw(6px);
        background: #C4DAEC;
        aspect-ratio: 29 / 20;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq() {
          grid-area: image;
          width: pc(476px);
          border-radius: pc(10px);
        }
        svg {
          height: vw(178px) !important;
          @include mq {
            height: pc(320px) !important;

          }
        }
      }
      .text {
        margin-top: vw(10px);
        @include mq() {
          grid-area: text;
          margin-top: unset;
        }
        .sub {
          i {
            display: block;
            font-family: var(--en);
            font-weight: var(--medium);
            font-size: vw(--fs-xxxs);
            color: var(--color-third-weakest);
            @include mq() {
              font-size: pc(--fs-m);
            }
            
          }
          p {
            margin-top: vw(8px);
            font-size: vw(--fs-m);
            font-family: var(--en);
            font-weight: var(--bold);
            text-transform: uppercase;
            color: #447CA9;
            @include mq() {
              margin-top: pc(8px);
              font-size: pc(--fs-xl);
            }
          }
        }
        h2 {
          margin-top: vw(24px);
          font-size: vw(--fs-xl);
          font-weight: var(--bold);
          margin-left: -.1em;
          @include mq() {
            margin-top: pc(32px);
            font-size: pc(--fs-xxxl);
            margin-left: -.05em;
          }
        }
        .lead {
          margin-top: vw(16px);
          font-size: vw(--fs-m);
          line-height: var(--line-height-medium);
          @include mq() {
            margin-top: pc(24px);
            font-size: pc(--fs-xl);
          }

        }
      }
      
    }

  }
  .p_button {
    margin-top: vw(42px);
    @include mq() {
      margin-top: pc(76px);
    }
    a {
      margin-inline: auto;
    }
  }
}
.m_top_column {
  margin-top: vw(-38px);
  @include mq() {
    margin-top: pc(-56px);
  }

  .p_button {
    margin-top: vw(22px);
    @include mq() {
      margin-top: pc(12px);
    }
    a {
      margin-inline: auto;
    }
  }
   
}
#topColumn {
  .swiper-wrapper {
    padding-top: vw(20px);
    @include mq {
      padding-top: pc(20px);
    }
  }
  .swiper-slide {
    margin-left: vw(15px);
    width: vw(308px);
    min-height: vw(433px);
    display: block grid;
    background-color: var(--color-surface);
    border-radius: vw(14px);
    grid-template: 
      "image" auto
      "text" 1fr
      "date" auto
      / 1fr;
      &:last-child {
        margin-right: vw(15px);
      }
    @include mq() {
      margin-left: pc(60px);
      width: pc(440px);
      min-height: pc(620px);
      border-radius: pc(20px);
      &:last-child {
        margin-right: pc(60px);
      }
      &:first-child {
        margin-left: calc(50vw - pc(630px));
      }
    }
    .image {
      img {
        border-radius: vw(14px) vw(14px) 0 0;
        height: vw(161px);
        object-fit: cover;
        width: 100%;
        @include mq() {
          border-radius: pc(20px) pc(20px) 0 0;
          height: pc(230px);
        }
      }
    }
    .text {
      padding: vw(20px) vw(14px);
      @include mq() {
        padding: pc(30px) pc(34px);
      }
      ul {
        margin-bottom: vw(16px);
        margin-left: -.1em;
        @include mq() {
          margin-bottom: pc(24px);
        }
        li {
          @include hashtag;

        }
      }
      h2 {
        font-size: vw(--fs-l);
        line-height: var(--line-height-xsmall);
        font-weight: var(--bold);
        @include mq() {
          font-size: pc(--fs-xl);
        }

      }
    }
    .date {
      padding: vw(24px) vw(20px);
      font-family: var(--en);
      font-weight: var(--medium);
      font-size: vw(--fs-xs);
      @include mq() {
        padding: pc(30px) pc(34px);
        font-size: pc(--fs-m);
      }

    }
  }
  .buttons {
    margin-top: vw(15px);
    padding-inline : vw(15px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: vw(10px);
    @include mq() {
      @include container;
      margin-top: pc(30px);
      gap: pc(20px);
    }
    .button {
      svg {
        width: vw(34px);
        @include mq() {
          width: pc(68px);
        }
        .path {
          fill: var(--color-surface);
          stroke: var(--color-black);
        }
        .arrow {
          fill: var(--color-black);
        }
      }
      &.columnPrev {
        svg {
          rotate: z 180deg;
        }
      }
      &.swiper-button-disabled {
        svg {
          .path {
            fill: #E4E4E4;
            stroke: #E4E4E4;
          }
          .arrow {
            fill: var(--color-surface);
          }
        }
      }
    }
  }

}
.m_top_about {
  background: rgba(196, 218, 236, 0.91);
  border-radius: vw(50px) vw(50px) 0 0;
  padding-block: vw(60px) vw(140px);
  margin-top: vw(160px);
  @include mq() {
    padding-block: pc(116px) pc(300px);
    margin-top: pc(340px);
    border-radius: pc(100px) pc(100px) 0 0;
  }
  .inner {
    padding-inline: vw(25px);
    position: relative;
    @include mq() {
      @include container;
    }
    .illust {
      display: block;
      margin-top: vw(-152px);
      @include mq() {
        margin-top: pcmax(-340px);
      }
      img {
        width: vw(173px);
        @include mq() {
          width: pc(560px);
        }
      }
    }
    .p_heading_large {
      margin-left: auto;
      text-align: right;
      @include mq() {
        margin-top: pcmax(-60px);
      }
      h1 {
        width: fit-content;
        margin-left: auto;
        color: var(--color-text-on-accent);
      }
      p {
        grid-template-columns: auto auto;
        justify-content: end;
      }
    }
    h2 {
      font-size: vw(--fs-xl);
      font-weight: var(--bold);
      margin-top: vw(16px);
      line-height: var(--line-height-xsmall);

      @include mq() {
        font-size: pc(--fs-xxxxl);
        margin-top: pc(10px); 
        .sp {
          display: none;
        }
      }
    }
    .lead {
      @include mq() {
        margin-top: pc(115px);
        margin-left: calc((50vw - pc(630px)) * -1);
        display: grid;
        column-gap: 12%;
        grid-template:
          "images text" auto
          "images button" auto
          / 56% 32%;
      }
      @include mq(pc) {
        margin-left: calc((50vw - pcmax(630px)) * -1);
      }
      .images {
        margin-left: vw(-25px);
        margin-top: vw(42px);
        display: flex;
        gap: vw(10px);
        @include mq() {
          grid-area: images;
          margin-top: unset;
          grid-area: images;
          margin-left: unset;
          gap: pc(20px);
          overflow: hidden;
        }
        img {
          height: vw(155px);
          width: auto;
          border-radius: vw(6px);
          @include mq() {
            height: pc(310px);
            border-radius: pc(12px);
          }

        }
      }
      .text {
        margin-top: vw(32px);
        font-size: vw(--fs-s);
        font-weight: var(--bold);
        line-height: var(--line-height-medium);
        @include mq() {
          grid-area: text;
          margin-top: unset;
          font-size: pc(--fs-l);
        }
      }
      .p_button {
        margin-top: vw(40px);
        text-align: right;
        @include mq() {
          grid-area: button;
          margin-top: unset;
          align-self: self-end;
        }
        a {
          margin-left: auto;
        }
      }

    }
  }
  
}
.m_top_news {
  background: var(--color-surface);
  border-radius: vw(50px) vw(50px) 0 0;
  padding-block: vw(64px) vw(100px);
  padding-inline: vw(25px);
  margin-top: vw(-50px);
  @include mq() {
    padding-block: pc(140px) pc(180px);
    padding-inline: unset;
    margin-top: pc(-100px);
    border-radius: pc(100px) pc(100px) 0 0;
  }
  .p_heading_large {
    @include mq() {
      @include container;
    }
    
  }
  .inner {
    margin-top: vw(50px);
    @include mq() {
      margin-top: pc(50px);
      @include container;
      display: grid;
      grid-template:
        "button news" auto
        / auto 70%;
    }
    .newslist {
      ul {
       @include newslist;
        @include mq() {
          grid-area: news;
        }
      }
    }
    .p_button {
      margin-top: vw(48px);

      @include mq() {
        grid-area: button;
        align-self: self-end;
      }
      a {
        margin-inline: auto;
        @include mq() {
          margin-inline: unset;
        }
      }
    }

  }
}