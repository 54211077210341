@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.pagenate {
  @include section;
  @include container;
  .inner {
    a, span {
      width: vw(42px);
      height: vw(42px);
      text-align: center;
      display: block;
      line-height: vw(44px);
      border-radius: var(--border-radius-circle);
      background-color: var(--color-surface);
      color: var(--color-text);
      font-family: var(--en);
      @include mq {
        @include hoverOp;
        width: pc(50px);
        height: pc(50px);
        line-height: pc(52px);
      }
      img {
        width: vw(14px);
        vertical-align: middle;
        translate: 0 vw(-1px);
        @include mq {
          width: pc(14px);
          translate: 0 pc(-1px);
        }
      }
      &.current, &.current_page {
        background-color: var(--color-text);
        color: var(--color-text-on-accent);
        font-weight: var(--bold);
        @include mq {
          &:hover {
            opacity: 1;
          }
        }
      }
      &.previouspostslink, &.link_before {
        img {
          transform: scale(-1, 1);
        }
      }
    }
    // wordpress
    .wp-pagenavi {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      a, span {
        margin: 0 vw(3px) vw(15px);
        @include mq {
          margin: 0 pc(10px) pc(20px);
        }
      }
    }
    // mt
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        margin: 0 vw(5px) vw(15px);
        @include mq {
          margin: 0 pc(10px) pc(20px);
        }
      }
    }
  }
}
