@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.pagenate_detail {
  @include section;
  @include container;
  .inner {
    position: relative;
    display: grid;
    justify-items: center;
    gap: vw(12px);
    grid-template:
      "prev next" auto
      "back back" auto
      / 1fr 1fr;
    @include mq {
      grid-template:
        "prev back next" auto
        / auto auto auto;
        justify-content: center;
      gap: pc(36px);
    }
    span {
      display: inline-block;
      a {
        position: relative;
        text-align: center;
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        gap: vw(12px);
        border-radius: var(--border-radius-pill);
        background-color: var(--color-surface);
        color: var(--color-text);
        padding: vw(16px) vw(24px);
        font-weight: var(--bold);
        color: var(--color-third-weak);
        @include mq {
          @include hoverOp;
          min-width: pc(170px);
          padding: pc(22px) pc(50px) pc(24px);
          gap: pc(24px);
          margin-inline: unset;
        }
        &::after {
          content: '';
          display: block;
          background-image: url(/images/arrow_pagenate.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: vw(12px);
          aspect-ratio: 1 /1;
          @include mq {
            width: pc(20px);
          }
        }
      }
      &.next {
        grid-area: next;
        margin-left: auto;
        @include mq {
          margin-left: unset;
        }
      }
      &.back {
        grid-area: back;
        a {
          background-color: var(--color-text);
          color: var(--color-text-on-accent);
          display: block;
          &::after {
            content: none;
          }
        }
      }
      &.prev {
        grid-area: prev;
        margin-right: auto;
        @include mq {
          margin-right: unset;
        }
        a {
          direction: rtl;
          &::after {
            rotate: z 180deg;
          }
        }
      }
    }
  }
}
