

$main: #9E7B4E;
$white: #fff;
$red: #AC2C31;
$black: #333333;
$main-dark:#895E27;
$main-light:$main;
$sub:#295171;
$sub-dark:$sub;
$third:#FFE940;
$third-light:#FFF28C;
$gray:#999999;
$lightgray: #F7F7F7;

$body-text-color: $black;

$padding-sp: 25px;

$radius-small: 10px;
$radius-default: 20px;
$radius-large: 0px;

// transitions settings

$bezier: cubic-bezier(.25,.1,.25,1);
$transition-speed: .5s;
$transition-speed-fast: .3s;


// debug settings

$responsive-debug-mode: false;


$imagepath: '/images/';