@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_table {
  @include module;
  @include container;
  .inner {
    .table_set {
      position: relative;
      overflow-x: auto;
      overflow-y: hidden;
      &.type_01 {
        table {
          border: none;
          th, td {
            border: none;
            padding: unset;
          }
        }
      }
      &.type_02 {
        table {
          th {
            padding-left: unset;
          }
        }
      }
      &.type_03 {
        table {
          th {
            background-color: var(--color-bg-table);
          }
        }
      }
      &.type_04 {
        th {
          background-color: var(--color-third-weak);
          color: var(--color-text-on-accent);
        }
      }
      &.type_06 {
        table {
          border-left: 1px solid var(--color-border);
          tr {
            &:nth-child(odd){
              background-color: var(--color-bg-table)
            }
            &:nth-child(even){
              background-color: var(--color-surface);
            }
            th, td {
              border-right: 1px solid var(--color-border);
              vertical-align: middle;
            }
          }
        }
      }
      &.type_07 {
        table {
          border-left: 1px solid var(--color-border);
          tr {
            &:nth-child(odd){
              background-color: var(--color-bg-table);
            }
            &:nth-child(even){
              background-color: var(--color-surface);
            }
            th, td {
              border-right: 1px solid var(--color-border);
            }
          }
        }
      }
      &.compact {
        table {
          th,td {
            padding: vw(4px) vw(10px);
            @include mq {
              padding: pc(12px) pc(15px);
            }
          }
        }
      }
      &.fixed {
        table {
          @include mq {
            table-layout: fixed;
          }
        }
      }
      &.fix_left {
        table {
          th {
            position: sticky;
            left: 0;
          }
        }
      }
      table {
        border-collapse: collapse;
        border-top: 1px solid var(--color-border);
        border-bottom: 1px solid var(--color-border);
        width: 100%;
        th, td {
          border-top: 1px solid var(--color-border);
          padding: vw(12px) vw(20px);
          vertical-align: top;
          line-height: var(--line-height-small);
          font-size: vw(--fs-s);
          @include mq {
            padding: pc(18px) pc(35px);
            font-size: pc(--fs-l);
          }
          &.left {
            text-align: left;
          }
          &.center {
            text-align: center;
          }
          &.right {
            text-align: right;
          }
          &.min {
            min-width: auto;
          }
          .p_button {
            margin-top: vw(15px);
            @include mq {
              margin-top: pc(15px);
            }
          }
        }
        th {
          font-weight: var(--bold);
          vertical-align: top;
          white-space: nowrap;
          @include mq {
            white-space: unset;
          }
        }
        td {
          min-width: vw(150px);
          @include mq {
            min-width: unset;
          }
        }
      }
    }
    .table_swipe {
      width: auto;
      overflow: auto;
      th {
        min-width: vw(150px);
        @include mq {
          min-width: unset;
        }
      }
      td {
        min-width: vw(150px);
        @include mq {
          min-width: unset;
        }
      }
    }
    .table_overflow {
      padding-top: vw(30px);
      position: sticky;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      @include mq {
        padding-top: pc(30px);
      }
      &:before {
        content: '';
        display: block;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: vw(20px) auto;
        background-image: url(/images/swipe.svg);
        width: vw(20px);
        height: vw(22px);
        margin-left: auto;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}
