@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_heading {
  @include section;
  &.large, &.large_b {
    .inner {
      @include container;
      position: static;
      .heading {
        @include heading-large;
      }
    }
  }
  &.medium {
    @include module;
    .inner {
      @include container;
      .heading {
        @include heading-medium;
      }
    }
  }
  &.small {
    @include module;
    .inner {
      @include container;
      .heading {
        @include heading-small;
      }
    }
  }
}
