@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.newslist {
  @include module;
  .inner {
    @include container;
    ul {
      @include newslist;
    }
  }
}
