@charset "UTF-8";
@import "mixins";

main:has(.m_top_news),
main:has(.with_footer_bg) {
  + footer {
    background-color: var(--color-surface);
    margin-top: unset;
    padding-top: unset;
  }
}
main:has(.navigation.breadcrumbs) {
  + footer {
    margin-top: unset;
    padding-top: unset;
  }
}
footer {
  margin-top: vw(100px);
  padding: vw(10px);
  @include mq() {
    margin-top: pc(200px);
    padding: pc(25px);
  }
  body:has(.topMv) & {
    margin-top: unset;
  }
  .footwrapper {
    background-color: var(--color-black);
    border-radius: vw(10px);
    padding-block: vw(10px) vw(20px);
    @include mq() {
      border-radius: pc(20px);
      padding-block: pc(70px) pc(60px);
    }
    .inner {
      padding-inline: vw(25px);
      color: var(--color-surface);
      @include mq() {
        width: pc(1250px);
        padding-inline: unset;
        margin-inline: auto;
        display: grid;
        grid-template: 
          "copy gnav" auto
          "credit copyright" auto
          / 35% 1fr;
          row-gap: pc(24px);
      }
      @include gnav;
      .main {
        margin-top: unset;
        @include mq() {
          padding-inline: unset;
          grid-area: gnav;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: pc(30px);
          row-gap: pc(50px);
        }
        li:first-of-type {
          border-top: none;
        }
        > li {
          @include mq {
            padding-block: unset;
            font-size: pc(--fs-m);
            border-top: none;
          }
          &:last-child {
            a {
              @include mq {
                margin-top:pc(8px);
              }
              &:first-of-type {
                margin-top: unset;
              }

            }
          }
          > a {
            @include mq {
              padding-block: pc(10px);
            }
          }
          ul {
            @include mq {
              margin-top: pc(10px);
              margin-left: unset;
              font-size: pc(--fs-s);
            }
            li {
              @include mq {
              }
              a {
                @include mq {
                  padding-block: pc(10px);
                }
              }
            }
          }
        }
      }
      .copy {
        margin-top: vw(85px);
        font-size: vw(--fs-xxl);
        font-weight: var(--bold);
        line-height: var(--line-height-xxsmall);
        letter-spacing: var(--letter-spacing-medium);
        @include mq() {
          grid-area: copy;
          margin-top: unset;
          font-size: pc(--fs-xxxxl);
        }
      }
      .credit {
        margin-top: vw(30px);
        @include mq() {
          grid-area: credit;
          margin-top: unset;
          align-self: end;
        }
        .logo {
          img {
            width: vw(48px);
            @include mq() {
              width: pc(84px);
            } 
          }
        }
        h1 {
          margin-top: vw(20px);
          font-size: vw(--fs-xs);
          font-weight: var(--bold);
          @include mq() {
            margin-top: pc(40px);
            font-size: pc(--fs-m);
          } 
        }
        address {
          margin-top: vw(5px);
          font-size: vw(--fs-xxxs);
          line-height: var(--line-height-small);
          letter-spacing: var(--letter-spacing-small);
          opacity: .8;
          font-family: var(--en);
          @include mq() {
            margin-top: pc(10px);
            font-size: pc(--fs-s);
          } 
        }
      }
      .copyright {
        margin-top: vw(40px);
        text-align: center;
        font-size: vw(--fs-xxxxs);
        border-top: 1px solid rgba(255,255,255,.15);
        padding-top: vw(25px);
        font-family: var(--en);
        @include mq() {
          grid-area: copyright;
          text-align: right;
          font-size: pc(--fs-xs);
          margin-top: unset;
          padding-top: unset;
          align-self: end;
          border-top: none;
        }
        .sns {
          li {
            a {
              @include hoverOp;
              img {
                width: vw(30px);
                opacity: .5;
                @include mq() {
                  width: pc(30px);
                }
              }
            }
          }
        }
        p {
          margin-top: vw(20px);
          line-height: var(--line-height-small);
          opacity: .8;
          @include mq() {
            margin-top: pc(20px);
          }
        }
      }
    }

  }
}