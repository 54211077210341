@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_faq {
  @include module;
  .inner {
    @include container;
    .unit {
      margin-top: vw(16px);
      background-color: var(--color-surface);
      border-radius: var(--border-radius-small);
      @include mq {
        margin-top: pc(24px);
        border-radius: var(--border-radius-small);
      }
      .q {
        padding: vw(24px) vw(45px) vw(24px) vw(24px);
        position: relative;
        font-size: vw(--fs-s);
        line-height: var(--line-height-medium);
        font-weight: var(--bold);
        transition: border-radius var(--transition-speed) var(--bezier), color var(--transition-speed) var(--bezier);
        @include mq {
          font-size: pc(--fs-l);
          padding: pc(48px) pc(80px) pc(48px) pc(50px);
          cursor: pointer;
          &:hover {
            color: var(--color-third-weak);
          }
        }
        &.active {
          &:after {
            background-image: url(/images/icon_minus.svg);
          }
        }
        &:after {
          @include pd;
          width: vw(14px);
          height: vw(14px);
          background: center / contain no-repeat url(/images/icon_plus.svg);
          right: vw(15px);
          top: vw(28px);
          transition: transform var(--transition-speed-fast) var(--bezier);
          @include mq {
            top: pc(50px);
            width: pc(20px);
            height: pc(20px);
            right: pc(30px);
          }
        }
      }
      .a {
        padding: vw(12px) vw(25px) vw(24px) vw(24px);
        position: relative;
        display: none;
        font-size: vw(--fs-s);
        line-height: var(--line-height-medium);
        @include mq {
          padding: pc(12px) pc(80px) pc(48px) pc(50px);
          font-size: pc(--fs-l);
        }
        &:after {
          content: '';
          display: block;
          clear: both;
        }
        img {
          display: block;
          margin: 0 auto vw(20px) !important;
          border-radius: var(--border-radius-small);
          &.mt-image-right {
            float: none !important;
            @include mq {
              float: right !important;
              margin: 0 0 pc(20px) pc(20px) !important;
            }
          }
          &.mt-image-left {
            float: none !important;
            @include mq {
              float: left !important;
              margin: 0 pc(20px) pc(20px) 0 !important;
            }
          }
        }
        
        p {
          padding-bottom: 1.5em;
          &:last-child {
            padding-bottom: unset;
          }
        }
      }
    }
    
  }
}
