@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_movie_multi {
  @include module;
  &.set2 {
    .inner {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, auto);
      grid-gap: vw(32px) 0;
      @include mq {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: pc(64px) pc(32px);
      }
    }
  }
  &.set3 {
    .inner {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, auto);
      grid-gap: vw(32px) 0;
      @include mq {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: pc(64px) pc(32px);
      }
    }
  }
  &.set4 {
    .inner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, auto);
      grid-gap: vw(32px) vw(16px);
      @include mq {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: pc(48px) pc(24px);
      }
    }
  }
  .inner {
    @include container;
    .movie {
      .movie_wrap_outer {
        width: 100%;
        &.shrink {
          margin-inline: auto;
        }
        .movie_wrap_inner {
          padding-top: 56.25%;
          position: relative;
          display: block;
          width: 100%;
          @include mq {
            width: 100%;
          }
          iframe {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            border-radius: var(--border-radius-small);
          }
        }
      }
      .caption {
        &.shrink {
          margin-inline: auto;
        }
      }
    }
  }
}
