@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_text_image {
  @include module;
  .inner {
    @include container;
    @include mq {
      display: flex;
      justify-content: space-between;
    }
    .text {
      margin-top: vw(30px);
      @include mq {
        width: 47%;
        margin-top: unset;
      }
    }
    .image {
      @include mq {
        width: 47%;
        margin-top: unset;
        padding-top: pc(10px);
      }
    }
  }
  &.reverse {
    .inner {
      @include mq {
        flex-direction: row-reverse;
      }
    }
  }
  &.compact {
    .inner {
      .text {
        @include mq {
          width: 64%;
        }
      }
      .image {
        text-align: center;
        @include mq {
          width: 30%;
        }
      }
    }
  }
  &.textbottom {
    @include parts;
    .inner {
      .text {
        @include mq {
          align-self: flex-end;
          padding-bottom: pc(10px);
          p {
            font-size: pc(20px);
          }
        }
      }
    }
  }
}
