@charset "UTF-8";
@import "mixins";


header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  transition: translate var(--transition-speed-slow) cubic-bezier(.17,.89,.6,1.18);
  body.is-down & {
    translate: 0 -120%;
    transition-timing-function: cubic-bezier(.72,-0.25,.85,.54);
  }
  body.is-fixed & {
    translate: 0 -120%;
    transition-timing-function: cubic-bezier(.72,-0.25,.85,.54);
  }
  body:has(#intro) & {
    opacity: 0;
  }
  body:has(#intro.is-finished) & {
    opacity: 1;
  }
  .inner {
    padding-inline: vw(18px);
    position: relative;
    height: vw(60px);
    display: flex;
    align-items: center;
    @include mq {
      height: pc(110px);
      padding-inline: pc(54px) pc(30px);
      justify-content: space-between;
    }
    #logo {
      position: relative;
      z-index: 100;
      a {
        @include hoverOp;
        img {
          width: vw(37px);
          @include mq() {
            width: pc(68px);
          }
        }
      }
    }

    #menuButton {
      @include hoverOp;
      cursor: pointer;
      position: absolute;
      right: vw(18px);
      z-index: 100;
      @include mq {
        display: none;
      }
      .lines {
        position: relative;
        width: vw(30px);
        height: vw(14px);
        @include mq {
          width: pc(30px);
          height: pc(14px);
        }
        span {
          position: absolute;
          left: vw(1px);
          width: vw(30px);
          height: 1px;
          background: var(--color-black);
          transition:
            transform var(--transition-speed) var(--bezier),
            background-color var(--transition-speed) var(--bezier) .2s,
            top var(--transition-speed) var(--bezier) .2s
          ;
          @include mq {
            width: pc(30px);
            left: pc(1px);
          }
          &.line01 {
            top: 0;
          }
          &.line02 {
            top: vw(7px);
            @include mq {
              top: pc(4px);
            }
          }
          &.line03 {
            top: vw(14px);
            @include mq {
              top: pc(8px);
            }
          }
        }
      }
      &.show {
        .lines {
          span {
            transition:
              background-color var(--transition-speed) var(--bezier),
              top var(--transition-speed) var(--bezier),
              opacity var(--transition-speed) var(--bezier),
              transform var(--transition-speed) var(--bezier) .2s
            ;
            &.line01 {
              top: vw(7px);
              transform: rotateZ(20deg);
              background-color: var(--color-text-on-accent);
              @include mq {
                top: pc(7px);
              }
            }
            &.line02 {
              opacity: 0;
            }
            &.line03 {
              top: vw(7px);
              transform: rotateZ(-20deg);
              background-color: var(--color-text-on-accent);
              @include mq {
                top: pc(7px);
              }
            }
          }
        }
      }
    }
    #gnav {
      position: fixed;
      inset: 0;
      z-index: 90;
      background-color: var(--color-black);
      padding: vw(80px) vw(30px) vw(30px) vw(30px);
      pointer-events: none;
      opacity: 0;
      transition: opacity var(--transition-speed-slow) var(--bezier);
      @include mq {
        position: static;
        opacity: 1;
        padding-inline: unset;
        background-color: transparent;
        padding-block: unset;
        pointer-events: auto;
      }
      &.show {
        pointer-events: auto;
        opacity: 1;
        .gnavinner {
          translate: 0;
          opacity: 1;
        }
      }
      .gnavinner {
        height: 100%;
        @include gnav;
        overflow: auto;
        scrollbar-color: transparent transparent;
        translate: 0 vw(20px);
        opacity: 0;
        transition: opacity var(--transition-speed-slow) var(--bezier), translate var(--transition-speed-slow) var(--bezier);
        @include mq {
          opacity: 1;
          translate: 0;
          padding-inline: unset;
        }
        .main {
          @include mq {
            display: flex;
            > li {
              font-size: pc(--fs-m);
              padding: unset;
              border-top: none;
              &:last-child {
                display: flex;
                a {
                  padding-inline: pc(20px);

                }
              }
              > a {
                color: var(--color-text);
                padding-block: unset;
                padding-inline: pc(26px);
                @include hoverColor(--color-brand);
              }
              ul {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}