@charset "UTF-8";
@import "mixins";

.article-wrapper {
  @include mq {
    @include section;
    @include container;
    display: grid;
    grid-template:
     "contents nav" auto
     / 1fr pc(280px);
     align-items: start;
     gap: pc(80px);
     position: relative;
     z-index: 1;
  }
  .article-nav {
    @include section;
    padding-inline: vw(--padding-sp);
    @include mq {
      position: sticky;
      top: pc(--margin-section);
      grid-area: nav;
      margin-top: unset;
      padding-inline: unset;
      mix-blend-mode: multiply;
    }
    ul {
      border-top: 1px solid var(--color-border);
      li {
        border-bottom: 1px solid var(--color-border);
        a {
          display: grid;
          padding-block: vw(16px);
          padding-inline: vw(5px);
          grid-template-columns: auto 1fr;
          gap: vw(16px);
          line-height: var(--line-height-small);
          @include mq {
            padding-block: pc(20px);
            padding-inline: pc(7px);
            gap: pc(16px);

          }
          @media (any-hover: hover) {
            &:hover {
              &::before {
                translate: 0 vw(3px);
                @include mq {
                  translate: pc(5px) 0;
                }
              }
            }
          }
          &::before {
            content: '';
            display: block;
            background-image: url(/images/arrow_bottom.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: translate var(--transition-speed) var(--bezier);
            width: vw(8px);
            height: 1lh;
            @include mq {
              width: pc(12px);
              aspect-ratio: unset;
              rotate: z 270deg;
              height: 1lh;
  
            }
          }

        }
      }
    }

  }
  .article-contents {
    padding-top: vw(--margin-module);
    @include mq {
      grid-area: contents;
      padding-top: unset;
      > *:first-child {
        margin-top: unset !important;
        padding-top: unset !important;
      }
      > a:first-child + * {
        margin-top: unset !important;
        padding-top: unset !important;
      }
      .container,
      .inner,
      .component.type_table,
      .component.type_catchcopy_image_bg,
      .component.type_conversion,
      .m_taglist {
        width: unset !important;
        margin-inline: unset;
      }
      .component.type_text {
        .inner {
          .text {
            max-width: unset;
            margin-inline: unset;
          }
        }
      }
      .component.type_pagetitle,
      .component.type_blogtitle {
        padding-right: unset;
        .inner {
          margin-left: calc((50vw - pc(630px)) * -1);
          .title, h1 {
            padding-right: pc(100px);

          }
        }
      }
      .component.type_extendedtitle .inner {
        margin-left: calc((50vw - pc(630px)) * -1);
        &:not(:has(.image)) {
          padding-right: unset;
        }
        .text {
          h1 {
            padding-right: pc(100px);

          }
        }
        .image {
          border-radius: 0 var(--border-radius) var(--border-radius) 0;
        }
      }
      .navigation.articlelist .inner .list, .component.type_pagelist .inner .list {
        grid-template-columns: repeat(2, 1fr);

      }
      .component.type_catchcopy_image .inner .image img {
        min-width: 100%;
      }
      .component.type_catchcopy_image_bg .inner .image {
        width: 82%;
      }
    }
    
  }
}