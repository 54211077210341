@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_blogtitle {
  padding-top: vw(60px);
  padding-right: vw(30px);
  @include mq {
    padding-top: pc(180px);
    padding-right: pc(90px);
  }
  .inner {
    padding: vw(32px) vw(24px);
    background-color: var(--color-surface);
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    @include mq {
      padding-inline: unset;
      padding-block: pc(100px);
    }
    h1 {
      font-size: vw(--fs-xl);
      font-weight: var(--bold);
      line-height: var(--line-height-xsmall);
      @include mq {
        font-size: pc(--fs-xxxxl);
        margin-left: calc(50vw - pc(630px));
        padding-right: pc(200px);
      }
    }
    .cat {
      margin-top: vw(16px);
      margin-left: -.1em;
      @include mq() {
        margin-top: pc(24px);
        margin-left: calc(50vw - pc(630px));
        translate: pc(-6px) 0;
      }
      a {
        margin-left: .5em;
        @include hashtag;

      }
    }
  }
}
