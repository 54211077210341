@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.articlelist {
  @include module;
  &.bg {
    background-color: var(--color-surface);
    padding-top: vw(60px);
    padding-bottom: vw(60px);
    margin-top: unset;
    @include mq {
      padding-top: pc(70px);
      padding-bottom: pc(80px);
    }
  }
  .inner {
    @include container;
    .list {
      margin-top: vw(42px);
      @include mq {
        margin-top: pc(60px);
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, auto);
        grid-gap: pc(80px) pc(52px);
      }
      .unit {
        position: relative;
        margin-top: vw(32px);
        background-color: var(--color-surface);
        border-radius: var(--border-radius-small);
        @include mq {
          display: block grid;
          margin-top: unset;
          min-height: pc(540px);
          grid-template-rows: auto 1fr;
        }
        &:first-child {
          margin-top: unset;
        }
        .image {
          a {
            img {
              border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
              object-fit: cover;
              aspect-ratio: 384 / 202;
              width: 100%;
            }
          }
        }
        .desc {
          padding: vw(24px) vw(24px);
          display: grid;
          grid-template:
            "cat" auto
            "title" auto
            "date" auto
            / 1fr;
          @include mq() {
            padding: pc(26px) pc(32px);
            grid-template:
              "cat" auto
              "title" 1fr
              "date" auto
              / 1fr;
          }
          .date {
            grid-area: date;
            font-family: var(--en);
            font-weight: var(--medium);
            font-size: vw(--fs-xs);
            padding-top: vw(40px);
            margin-left: .1em;
            @include mq() {
              padding-top: unset;
              font-size: pc(--fs-m);
            }
          }
          .cat {
            grid-area: cat;
            margin-bottom: vw(16px);
            margin-left: -.1em;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            
            gap: vw(8px);
            @include mq() {
              margin-bottom: pc(20px);
              gap: pc(8px);
            }
            a {
              @include hashtag;
            }
          }
          .title {
            grid-area: title;
            font-size: vw(--fs-l);
            line-height: var(--line-height-xsmall);
            font-weight: var(--bold);
            @include mq() {
              font-size: pc(--fs-xl);
            }
            a {
              color: var(--color-black);
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
