@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.localnav {
  @extend .navigation, .localnav_sub;
  margin-top: unset !important;
  padding-top: vw(80px);
  @include mq {
    padding-top: pc(110px);
  }
  .inner {
    padding-inline: vw(18px);
    @include mq {
      padding-inline: pc(54px) !important;
      width: auto !important;
    }
    .overflow {
      overflow: auto;
      @include mq {
        overflow: visible;
        padding: 0;
      }
      ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        @include mq {
          justify-content: end;
          flex-wrap: wrap;
        }
      }
    }
  }
}
