@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_wysiwyg {
  @include module;
  .inner {
    @include container;
    .card-body {
      background-color: var(--color-surface);
      border-radius: var(--border-radius-small);
      padding: vw(30px) vw(20px);
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: vw(20px);
      @include mq {
        padding: pc(80px) pc(60px);
        gap: pc(40px);
      }
      .form-group {
        @include mq {
          width: pc(680px);
          margin-inline: auto;
        }
        label {
          font-weight: var(--bold);
          font-size: vw(--fs-s);
          display: block;
          margin-bottom: vw(10px);
          @include mq {
            font-size: pc(--fs-l);
            margin-bottom: pc(14px);
          }
          .badge {
            display: inline-block;
            padding: .25em .4em .2em;
            font-size: vw(--fs-xxxxs);
            border-radius: .25rem;
            translate: 0 vw(-2px);
            @include mq {
              font-size: pc(--fs-xs);
              translate: 0 pc(-2px);
            }
            &.badge-danger {
              color: #fff;
              background-color: #dc3545;
            }
          }
        }
        .form-control {
          display: block;
          width: 100%;
          padding: vw(6px) vw(12px);
          font-size: vw(--fs-m);
          font-weight: 400;
          line-height: 1.5;
          color: inherit;
          background-color: var(--color-surface);
          background-clip: padding-box;
          border: 1px solid var(--color-border);
          border-radius: .25rem;
          transition: border-color var(--transition-speed-fast) var(--bezier);
          @include mq {
            padding-block: pc(12px);
            padding-inline: pc(24px);
            font-size: pc(--fs-l);
          }
          &:focus {
            color: inherit;
            border-color: var(--color-third-weakest);
            outline: 0;
          }
        }
      }
      .btn {
        @include button(apply);
        margin-inline: auto;
        text-align: center;
      }
    }
  }
}
