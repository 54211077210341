@charset "UTF-8";

@import "mixins";

.component.type_conversion {
  @include section;
  @include container;
  .inner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: var(--color-third-weak);
    color: var(--color-text-on-accent);
    text-align: left;
    border-radius: var(--border-radius-small);
    padding: vw(32px) vw(24px);
    @include mq {
      padding: pc(64px) pc(48px);
      border-radius: var(--border-radius);
    }
    &.center {
      text-align: center;
      .button {
        a {
          margin-inline: auto;
        }
      }
    }
    &.right {
      text-align: right;
      .button {
        a {
          margin-left: auto;
        }
      }
    }
    .image {
      text-align: inherit;
      margin-bottom: vw(24px);
      @include mq {
        margin-bottom: pc(32px);
      }
      img {
        max-width: 50%;
        @include mq {
          max-width: 20%;
        }
      }
    }
    .copy {
      h4 {
        font-size: vw(--fs-xl);
          font-weight: var(--bold);
          line-height: var(--line-height-xsmall);
        @include mq {
          font-size: pc(--fs-xxxl);
        }
      }
    }
    .desc {
      margin-top: vw(12px);
      @include mq {
        margin-top: pc(12px);
      }
      p {
        font-size: vw(--fs-xs) !important;
        line-height: var(--line-height-small) !important;
        @include mq {
          font-size: pc(--fs-m) !important;
          line-height: var(--line-height-small) !important;
        }
      }
    }
    .button {
      text-align: inherit;
      margin-top: vw(32px);
      @include mq {
        margin-top: pc(48px);
      }
      a {
        text-align: center;
        @include button(medium, reverse);
      }
    }
  }
}
