@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_extendedtitle {
  padding-top: vw(60px);
  @include mq {
    padding-top: pc(180px);
  }
  &:has(.image) {
    @include mq {
      padding-top: pc(114px);
    }
  }
  .inner {
    position: relative;
    padding-right: vw(30px);
    @include mq {
      padding-right: pc(90px);
    }
    &:has(.image) {
      padding-top: vw(180px);
      @include mq {
        padding-top: pc(375px);
      }
    }
    .image {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: vw(360px);
      height: 66%;
      border-radius: var(--border-radius) 0 0 var(--border-radius);
      @include mq {
        height: 80%;
        width: pc(1210px);
      }
      img {
        width: vw(360px);
        height: 100%;
        object-fit: cover;
        object-position: center;
        @include mq {
          width: pc(1210px);
        }
      }
    }
    .text {
      position: relative;
      z-index: 2;
      padding: vw(32px) vw(24px);
      background-color: var(--color-surface);
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
      @include mq {
        padding-inline: unset;
        padding-block: pc(100px);
      }
      @include mq(pc) {
        padding-inline: unset;
        padding-block: pc(100px);
      }
      &:has(.partner) {
        @include mq() {
          padding-block: pc(50px);
        }
      }
      .tag {
        font-size: vw(--fs-s);
        font-weight: var(--bold);
        color: var(--color-third-weakest);
        font-family: var(--en);
        padding-bottom: vw(4px);
        text-transform: uppercase;
        @include mq {
          font-size: pc(--fs-xl);
          margin-left: calc(50vw - pc(630px));
          translate: pc(6px) 0;
          padding-bottom: pc(8px);
        }
      }
      h1 {
        font-size: vw(--fs-xl);
        font-weight: var(--bold);
        line-height: var(--line-height-xsmall);
        @include mq {
          font-size: pc(--fs-xxxxl);
          margin-left: calc(50vw - pc(630px));
          padding-right: pc(200px);
        }
      }
      span {
        font-size: vw(--fs-xs);
        font-weight: var(--bold);
        display: grid;
        grid-template-columns: auto 1fr;
        gap: vw(7px);
        margin-top: vw(4px);
        line-height: var(--line-height-small);
        grid-area: desc;
        @include mq() {
          gap: pc(13px);
          margin-top: pc(13px);
          margin-left: calc(50vw - pc(630px));
          translate: pc(6px) 0;
        }
        &::before {
          content: '';
          display: block;
          height: 1lh;
          background-image: url('/images/icon_heading.svg');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          width: vw(7px);
          @include mq() {
            width: pc(13px);
          }
        }
        @include mq() {
          font-size: pc(--fs-l);
        }
      }
      .partner {
        margin-top: vw(55px);
        gap: vw(6px);
        text-align: right;
        @include mq() {
          margin-top: pc(60px);
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          margin-left: calc(50vw - pc(630px));
          gap: pc(12px);
          padding-right: pc(60px);
        }
        dt {
          font-weight: var(--bold);
          font-size: vw(--fs-xxxs);
          line-height: var(--line-height-small);
          @include mq() {
            font-size: pc(--fs-s);
          }

        }
        dd {
          img {
            max-height: vw(50px);
            max-width: vw(150px);
            @include mq() {
              max-height: pc(60px);
              max-width: pc(190px);
            }
          }
        }
      }
    }
  }
}
