@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_heading_ex {
  @include section;
  .inner {
    @include container;
  }
}
