@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.localnav_sub {
  @include module;
  .navigation.archivenav + & {
    @include parts;
  }
  .inner {
    @include container;
    @include localnav;
  }
}
