@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.breadcrumbs {
  @include section;
  @include mq {
    padding-bottom: pc(20px);
  }
  .inner {
    display: none;
    @include container;
    @include localnav;
    @include mq {
      display: block;
    }
  }
}
