@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.footer_util {
  padding-bottom: 40px;
  padding-top: 40px;
  background: center / cover no-repeat url(/images/footer_util_bg.jpg);
  @include mq {
    padding: 50px 0;
  }
  .inner {
    @include container();
    @include grid(2,20px,20px);
    @include mq {
      @include grid(4,27px,27px);
    }
    .unit {
      background-color: white;
      aspect-ratio: 158 / 140;
      border-radius: $radius-default;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @include mq {
        aspect-ratio: 12 / 7;
        flex-direction: row;
        justify-content: flex-start;
        padding: 0 20px;
        transition: all ease-in .2s;
        &:hover {
          transform: translateY(-3px);
          box-shadow: 0 8px 10px 0 rgba(0,0,0,.1);
        }
      }
      .icon {
        img {
          width: 52px;
          height: 52px;
        }
      }
      .desc {
        text-align: center;
        margin-top: 6px;
        @include mq {
          text-align: left;
          margin-left: 15px;
          margin-top: 0;
        }
        h2 {
          font-size: vw(16px);
          
          font-family: var(--en);
          font-weight: var(--bold);
          color: $black;
          @include mq {
            font-size: pc(20px);
            
          }
        }
        p {
          margin-top: 2px;
          font-size: vw(10px);
          
          color: $gray;
          @include mq {
            font-size: vw(11px);
            margin-top: 0;
          }
        }
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        color: $black;
        
        position: relative;
        margin-right: 3px;
        &:after {
          content: '\FF0F';
          margin-left: 3px;
          color: $gray;
        }
        &:last-child {
          &:after {
            content: none;
          }
        }
        a {
          color: $gray;
          @include hoverColor;
          text-decoration: underline;
        }
      }
    }
  }
}
