@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_catalog {
  @include module;
  .inner {
    @include container;
    .list {
      margin-top: vw(40px);
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, auto);
      grid-gap: vw(40px) 0;
      @include mq {
        margin-top: pc(60px);
        grid-template-columns: repeat(3, 1fr);
        grid-gap: pc(60px) pc(50px);
        padding-bottom: pc(60px);
        border-bottom: 1px solid var(--color-border);
      }
      .unit {
        position: relative;
        &:before {
          @include pd();
          @include mq {
            width: 1px;
            height: 100%;
          }
        }
        &:nth-child(3n){
          &:before {
            content: none;
          }
        }
        .main {
          display: flex;
          gap: 5%;
          @include mq {
            display: block;
          }
          .image {
            width: 35%;
            @include mq {
              width: 100%;
            }
            a {
              display: block;
              @include mq {
                aspect-ratio: 286 / 190;
                display: flex;
                justify-content: center;
                align-items: center;
                @include hoverOp;
              }
            }
            img {
              border-radius: 0;
              @include mq {
                max-height: 100%;
              }
            }
          }
          .desc {
            width: 60%;
            @include mq {
              width: auto;
            }
            .title {
              padding-bottom: 0;
              font-size: vw(--fs-l);
              line-height: var(--line-height-xsmall);
              @include mq {
                @include hoverOp;
                margin-top: pc(30px);
                font-size: pc(--fs-xl);
              }
              a {
                color: var(--color-black);
                font-weight: var(--bold);
                position: relative;
                text-decoration: none;
                word-break: break-all;
              }
            }
            .subtitle {
              margin-top: vw(6px);
              font-size: vw(--fs-s);
              line-height: var(--line-height-xsmall);
              @include mq {
                margin-top: pc(10px);
                font-size: pc(--fs-m);
              }
            }
            .format {
              margin-top: vw(8px);
              font-size: vw(--fs-xs);
              @include mq {
                margin-top: pc(14px);
                font-size: pc(--fs-s);
              }
              img {
                width: vw(20px);
                height: vw(22px);
                object-fit: contain;
                margin-right: vw(8px);
                vertical-align: middle;
                @include mq {
                  width: pc(20px);
                  height: pc(22px);
                  margin-right: pc(8px);
                }
              }
              span {
                text-transform: uppercase;
              }
              a {
                color: var(--color-third-weak);
                text-decoration: none;
                @include mq {
                  @include hoverOp;
                }
              }
            }
          }
        }
        .summary {
          margin-top: vw(24px);
          font-size: vw(--fs-s);
          line-height: var(--line-height-medium);
          @include mq {
            margin-top: pc(24px);
            font-size: pc(--fs-m);
          }
        }
      }
    }
    .more {
      margin-top: vw(40px);
      text-align: center;
      @include mq {
        margin-top: pc(40px);
      }
    }
  }
}
