@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.archivenav {
  @include module;
  .inner {
    @include container;
    .overflow {
      overflow: auto;
      padding-bottom: vw(10px);
      @include mq {
        overflow: visible;
        padding-bottom: unset;
      }
      ul {
        display: flex;
        justify-content: flex-start;
        @include mq {
          flex-wrap: wrap;
        }
        li {
          white-space: nowrap;
          margin-left: vw(4px);
          position: relative;
          &:first-child {
            margin-left: unset;
          }
          @include mq {
            margin-left: pc(16px);
          }
          &.active,&.current_page_item {
            z-index: 2;
            a {
              background-color: var(--color-surface);
              border: 1px solid var(--color-second);
            }
          }
          a {
            @include hashtag(large);
            border: 1px solid var(--color-second);
          }
        }
      }
    }
  }
}
