@charset "UTF-8";

@import "mixins";

.component.type_catchcopy_image {
  @include section;
  .inner {
    @include container;
    @include mq {
      display: flex;
      flex-direction: row-reverse;
      gap: pc(60px);
      position: relative;
    }
    .image {
      @include mq {
        width: 47%;
      }
      img {
        object-fit: cover;
        min-width: calc(50vw - (vw(60px) / 2));
        border-radius: var(--border-radius-small);
        @include mq(pc) {
          min-width: calc(40vw - (pc(60px) / 2));
          border-radius: var(--border-radius);
        }
      }
    }
    .text {
      margin-top: vw(32px);
      @include mq {
        margin-top: unset;
        width: 47%;
      }
      h3 {
        @include heading-small;
      }
      .copy {
        h4 {
          margin-top: vw(24px);
          font-size: vw(--fs-xl);
          font-weight: var(--bold);
          line-height: var(--line-height-xsmall);
          @include mq {
            margin-top: pc(32px);
            font-size: pc(--fs-xxxl);
          }
        }
      }
      .desc {
        margin-top: vw(32px);
        @include mq {
          margin-top: pc(64px);
        }
      }
      .button {
        margin-top: vw(24px);
        @include mq {
          margin-top: pc(48px);
        }
        a {
          @include button;
          text-align: center;
        }
      }
    }
  }
  &.reverse {
    .inner {
      @include mq {
        flex-direction: row;
      }
      .image {
        @include mq {
          direction: rtl;
        }
      }
    }
  }
}
