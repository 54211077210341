@import "variables";

$breakpoints: (
  'min': 'print, screen and (max-width: 374px)',
  'sp': 'print, screen and (min-width: 375px)',
  'spl': 'print, screen and (max-width: 768px) and (orientation:landscape)',
  'tb': 'print, screen and (min-width: 768px)',
  'tbl': 'print, screen and (min-width: 1080px)',
  'pc': 'print, screen and (min-width: 1440px)',
  'pclarge': 'print, screen and (min-width: 1600px)',
) !default;

@mixin mq($breakpoint: tb) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@function is-css-var($value) {
  $first-chars: str-slice(#{$value}, 1, 2);
  @return $first-chars == '--' or str-index(#{$value}, 'var(--') != null;
}
@mixin pd {
  position: absolute;
  content: '';
  display: block;
}
@mixin hoverOp($op:0.65) {
  transition: opacity .3s var(--bezier);
  @media (any-hover: hover) {
    &:hover {
      opacity: $op;
    }
  }
}
@mixin hoverColor($color) {
  transition: color .3s var(--bezier);
  @media (any-hover: hover) {
    &:hover {
      @if is-css-var($color) {
        @if str-index(#{$color}, 'var(') != null {
          color: #{$color};
        } @else {
          color: var(#{$color});
        }
      } @else {
        color: $color;
      }
    }
  }
}
@mixin container() {
  padding-left: vw(--padding-sp);
  padding-right: vw(--padding-sp);
  @include mq() {
    padding-left: unset;
    padding-right: unset;
    width: pc(1260px);
    margin-inline: auto;
  }
}
@mixin paddingLR {
  padding-left: vw(--padding-sp);
  padding-right: vw(--padding-sp);
  @include mq {
    padding-left: unset;
    padding-right: unset;
  }
}
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@function vw($px, $base: 390px) {
  @if is-css-var($px) {
    @if str-index(#{$px}, 'var(') != null {
      @return calc(#{$px} / #{strip-unit($base)} * 100vw);
    }
    @return calc(var(#{$px}) / #{strip-unit($base)} * 100vw);
  }

  $value: $px;
  @if (unit($px) != 'px') {
    @warn 'vw()の引数にpx以外の値を指定しても計算できません';
    @return $value;
  }
  $value: calc(#{strip-unit($px)} / #{strip-unit($base)} * 100vw);
  @return $value;
}

@function pc($px, $base: 1440px) {
  @if is-css-var($px) {
    @if str-index(#{$px}, 'var(') != null {
      $vw-value: calc(#{$px} / #{strip-unit($base)} * 100vw);
      @return min(calc(#{$px} * 1px), #{$vw-value});
    }
    $vw-value: calc(var(#{$px}) / #{strip-unit($base)} * 100vw);
    @return min(calc(var(#{$px}) * 1px), #{$vw-value});
  }

  $value: $px;
  @if (unit($px) != 'px') {
    @warn 'pc()の引数にpx以外の値を指定しても計算できません';
    @return $value;
  }
  $value: calc(#{strip-unit($px)} / #{strip-unit($base)} * 100vw);
  @return min(#{$px}, #{$value});
}


@function pcmax($px, $base: 1440px) {
  @if is-css-var($px) {
    @if str-index(#{$px}, 'var(') != null {
      $vw-value: calc(#{$px} / #{strip-unit($base)} * 100vw);
      @return max(calc(#{$px} * 1px), #{$vw-value});
    }
    $vw-value: calc(var(#{$px}) / #{strip-unit($base)} * 100vw);
    @return max(calc(var(#{$px}) * 1px), #{$vw-value});
  }

  $value: $px;
  @if (unit($px) != 'px') {
    @warn 'pcmax()の引数にpx以外の値を指定しても計算できません';
    @return $value;
  }
  $value: calc(#{strip-unit($px)} / #{strip-unit($base)} * 100vw);
  @return max(#{$px}, #{$value});
}



@mixin gnav($footer: false) {
  .main {
    > li {
      font-family: var(--en);
      font-weight: var(--demi);
      text-transform: uppercase;
      font-size: vw(--fs-s);
      padding-block: vw(20px) vw(20px);
      border-top: 1px solid rgba(255,255,255,.25);
      > a {
        color: var(--color-text-on-accent);
        padding-block: vw(10px);
        display: block;
        @include hoverColor(--color-second);
        
      }
      ul {
        margin-top: vw(10px);
        margin-left: vw(30px);
        font-size: vw(--fs-xs);
        li {
          font-weight: 400;
          a {
            display: block;
            color: var(--color-text-on-accent);
            padding-block: vw(10px);
            @include hoverColor(--color-second);
          }
        }
      }
    }
  }
}

@mixin sp_pc {
  &.sp {
    @include mq {
      display: none;
    }
  }
  &.pc {
    display: none;
    @include mq {
      display: block;
    }
  }
}

@mixin button($size:medium, $color:default) {
  display: grid;
  width: fit-content;
  background-color: var(--color-black);
  font-weight: var(--bold);
  font-size: vw(--fs-s);
  font-family: var(--en);
  padding: vw(20px) vw(32px) vw(19px) vw(24px);
  color: var(--color-text-on-accent);
  border-radius: var(--border-radius-pill);
  grid-template-columns: auto 1fr;
  gap: vw(12px);
  align-items: center;
  overflow: hidden;
  text-decoration: none;
  transition: background-color .3s var(--bezier);
  line-height: 1;
  @include mq() {
    font-size: pc(20px);
    padding: pc(29px) pc(48px) pc(28px) pc(36px);
    gap: pc(20px);
  }
  @media (any-hover: hover) {
    &:hover {
      background-color: var(--color-brand);
      &::before {
        translate: vw(4px) 0;
        @include mq() {
          translate: pc(4px) 0;
        }
      }
    }
  }
  span {
    position: relative;
    z-index: 2;
  }
  &::before {
    content: '';
    display: block;
    width: vw(7.5px);
    aspect-ratio: 75 / 52;
    background-color: var(--color-brand-weak);
    mask-image: url('/images/mask_01.svg');
    mask-repeat: no-repeat;
    mask-size: contain;
    position: relative;
    z-index: 1;
    transition: translate .3s var(--bezier);
    margin-top: vw(-2px);
    @include mq() {
      width: pc(12px);
      margin-top: pc(-2px);
    }
  }
  @if $size == small {
    font-size: vw(--fs-xs);
    padding-block: vw(16px) vw(15px) ;
    @include mq() {
      font-size: pc(--fs-m);
      padding-block: pc(26px) pc(25px);
    }
  }
  @if $size == xsmall {
    font-size: vw(--fs-xxs);
    padding-block: vw(12px) vw(13px) ;
    gap: vw(8px);
    @include mq() {
      font-size: pc(--fs-s);
      padding-block: pc(20px) pc(19px);
      gap: pc(12px);
    }
  }
  @if $color == reverse {
    background-color: var(--color-surface);
    color: var(--color-text);
    &::before {
      background-color: var(--color-brand);
    }

  @media (any-hover: hover) {
    &:hover {
      background-color: var(--color-surface);
    }
  }
  }
  @else if $size == apply {
    min-width: vw(280px);
    @include mq() {
      min-width: pc(500px);
    }
  }
}
@mixin hoverOp($op:0.7) {
  transition: opacity .3s $bezier;
  &:hover {
    opacity: $op;
  }
}
@mixin hoverColor($color:$main) {
  transition: color .3s $bezier;
  &:hover {
    color: $color;
  }
}

@mixin grid($cols,$gap-vert,$gap-horiz) {
  display: grid;
  grid-template-columns: repeat($cols, 1fr);
  grid-template-rows: repeat(1, auto);
  grid-gap: $gap-vert $gap-horiz;
}

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}
@mixin section {
  margin-top: vw(--margin-section);
  @include mq {
    margin-top: pc(--margin-section-pc);
  }
}
@mixin module {
  margin-top: vw(--margin-module);
  @include mq {
    margin-top: pc(--margin-module-pc);
  }
}
@mixin parts {
  margin-top: vw(--margin-parts);
  @include mq {
    margin-top: pc(--margin-parts-pc);
  }
}
@mixin heading-small {
  position: relative;
  font-weight: var(--bold);
  font-size: vw(--fs-m);
  line-height: var(--line-height-xsmall);
  display: grid;
  grid-template-columns: auto 1fr;
  gap: vw(8px);
  color: var(--color-third-weak);
  font-family: var(--en);
  @include mq {
    font-size: pc(--fs-xl);
    gap: pc(16px);
  }
  &::before {
    content: '';
    display: block;
    height: 1lh;
    background-image: url('/images/icon_heading.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: translateY(-0.1em);
    width: vw(10px);
    @include mq() {
      width: pc(16px);
    }
  }
}
@mixin heading-medium {
  position: relative;
  font-weight: var(--bold);
  font-size: vw(--fs-l);
  line-height: var(--line-height-xsmall);
  display: grid;
  grid-template-columns: auto 1fr;
  gap: vw(10px);
  @include mq {
    font-size: pc(--fs-xxl);
    gap: pc(20px);
  }
  &::before {
    content: '';
    display: block;
    height: 1lh;
    background-image: url('/images/icon_heading.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: translateY(-0.1em);
    width: vw(13px);
    @include mq() {
      width: pc(23px);
    }
  }
}
@mixin heading-large {
  font-weight: var(--bold);
  font-size: vw(--fs-xl);
  line-height: var(--line-height-xsmall);
  text-decoration: underline;
  text-decoration-thickness: 0.35em;
  text-decoration-color: var(--color-second);
  text-underline-offset: -0.1em;
  text-decoration-skip-ink: none;
  @include mq {
    font-size: pc(--fs-xxxl);
  }
}

@mixin hashtag($size:default) {
  background-color: var(--color-second);
  border-radius: var(--border-radius-pill);
  font-weight: var(--bold);
  font-size: vw(--fs-xxxxs);
  padding: vw(6px) vw(10px) vw(4px);
  display: inline grid;
  grid-template-columns: auto 1fr;
  line-height: 1;
  gap: vw(3px);
  font-family: var(--en);
  text-decoration: none;
  color: var(--color-text);
  @include mq() {
    font-size: pc(--fs-m);
    padding: pc(10px) pc(20px) pc(7px);
    gap: pc(10px);
  }
  @if $size == large {
    font-size: vw(--fs-xxs);
    padding: vw(8px) vw(16px) vw(6px);
    gap: vw(6px);
    @include mq() {
      font-size: pc(--fs-l);
      padding: pc(14px) pc(20px) pc(12px);
      gap: pc(10px);
    }
  }
  &::before {
    content: '#';
    font-weight: var(--medium);
  }
}
@mixin newslist {
  border-bottom: 1px solid #e1e1e1;
  li {
    border-top: 1px solid #e1e1e1;
    a {
      display: grid;
      grid-template:
        "date cat" auto
        "title title" auto
        / auto 1fr;
      padding: vw(20px) 0;
      column-gap: vw(15px);
      row-gap: vw(15px);
      @include mq() {
        padding: pc(30px) 0;
        grid-template:
          "date cat title" auto
          / auto auto 1fr;
        column-gap: pc(30px);
        align-items: start;
      }
      .date {
        font-size: vw(--fs-xs);
        font-family: var(--en);
        font-weight: var(--medium);
        grid-area: date;
        align-self: center;
        @include mq() {
          font-size: pc(--fs-m);
          align-self: start;
          line-height: var(--line-height-large);
          width: pc(125px);
        }
      }
      .cat {
        grid-area: cat;
        i {
          text-align: center;
          display: inline-block;
          background-color: var(--color-surface);
          font-size: vw(--fs-xxxs);
          font-weight: var(--bold);
          padding: vw(4px) vw(10px);
          min-width: vw(115px);
          border: 1px solid var(--color-black);
          border-radius: var(--border-radius-pill);
          @include mq() {
            min-width: pc(164px);
            font-size: pc(--fs-s);
            padding: pc(8px) pc(10px) pc(10px);
          }

        }
      }
      h2 {
        position: relative;
        grid-area: title;
        margin-top: var(--space-xsmall);
        font-size: vw(--fs-s);
        font-weight: var(--bold);
        line-height: var(--line-height-small);
        @include mq() {
          margin: 0;
          flex: 1;
          font-size: pc(--fs-l);
        }
      }
    }
  }
}

@mixin localnav {
  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      margin-right: vw(10px);
      margin-bottom: vw(10px);
      white-space: nowrap;
      font-weight: var(--demi);
      font-size: vw(--fs-s);
      font-family: var(--en);
      display: grid;
      align-items: center;
      grid-template: 
        "slash text" auto
        / auto auto;
      gap: vw(10px);
      text-transform: uppercase;
      @include mq() {
        font-size: pc(--fs-m);
        margin-right: pc(10px);
        margin-bottom: pc(10px);
        gap: pc(10px);
      }
      &::before {
        grid-area: slash;
        content: "";
        display: block;
        width: vw(16px);
        height: vw(0.5px);
        background-color: var(--color-black);
        rotate: z 326deg;
        translate: 0 vw(-1px);
        @include mq() {
          width: pc(24px);
          height: pc(0.5px);
          rotate: z 326deg;
          translate: 0 pc(-1px);
        }
      }
      &.active,&.current_page_item {
        a {
          color: var(--color-third-weak);
        }
      }
      &:first-child {
        display: inline-block;
        &::before {
          content: unset;
        }
      }
      a {
        grid-area: text;
        display: inline-block;
        color: var(--color-text);
        text-decoration: none;
      }
    }
  }
}

@mixin fs($fts, $lin: 14,$ls:0) {
  $rem: calc($fts / 10);
  $linS: calc($lin / $fts);
  $lsm: calc($ls * 0.001);
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  line-height: $linS;
  letter-spacing: #{$lsm}em;
  
}

@mixin text($size:medium){
  @if $size == xxsmall {
    font-family: var(--en);
    @include fs(10,16,50);
    @include mq(tbl) {
      @include fs(12,18,50);
    }
  }
  @if $size == xsmall {
    font-family: var(--en);
    @include fs(11,18,50);
    @include mq(tbl) {
      @include fs(13,21,50);
    }
  }
  @if $size == small {
    @include fs(12,22,50);
    @include mq(tbl) {
      @include fs(14,28,50);
    }
  }
  @if $size == medium {
    font-family: var(--en);
    @include fs(15,28,50);
    @include mq(tbl) {
      @include fs(16,34,50);
    }
  }
  @if $size == large {
    font-family: var(--en);
    @include fs(16,30,50);
    @include mq(tbl) {
      @include fs(18,32,50);
    }
  }
  @if $size == xlarge {
    font-family: var(--en);
    @include fs(18,32,50);
    @include mq(tbl) {
      @include fs(20,34,50);
    }
  }
  @if $size == caption {
    @include fs(11,18,50);
    color: $gray;
    @include mq(tbl) {
      @include fs(13,21,50);
    }
  }
  @if $size == heading-large {
    font-family:var(--en);
    font-weight: var(--bold);
    @include fs(20,34,50);
    @include mq(tbl) {
      @include fs(32,58,50);
    }
  }
  @if $size == heading-medium {
    font-family:var(--en);
    font-weight: var(--bold);
    @include fs(17,25,100);
    @include mq(tbl) {
      @include fs(26,46,100);
    }
  }
  @if $size == heading-small {
    font-family:var(--en);
    font-weight: var(--bold);
    @include fs(14,20,100);
    @include mq(tbl) {
      @include fs(20,30,100);
    }
  }
  @if $size == heading-xsmall {
    font-family:var(--en);
    font-weight: var(--bold);
    @include fs(14,20,100);
    @include mq(tbl) {
      @include fs(20,30,100);
    }
  }
  @if $size == heading-xxsmall {
    font-family:var(--en);
    font-weight: var(--bold);
    @include fs(14,20,100);
    @include mq(tbl) {
      @include fs(20,30,100);
    }
  }
}

@mixin slickdots($width:8px,$height:8px) {
  text-align: center;
  li {
    display: inline-block;
    width: auto;
    padding: 0;
    margin: 0 5px;
    button {
      border: none;
      background-color: var(--color-surface);
      color: transparent;
      height: $height;
      width: $width;
      padding: 0;
      cursor: pointer;
      border-radius: 0;
    }
    &.slick-active {
      button {
        background-color: var(--color-third-weak);
      }
    }
  }
}

@mixin topmargin($size:medium){
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 60px;
  }
  @if $size == small {
    margin-top: 25px;
    @include mq(tbl) {
      margin-top: 40px;
    }
  }
  @else if $size == large {
    margin-top: 60px;
    @include mq(tbl) {
      margin-top: 80px;
    }
  }
  @else if $size == xlarge {
    margin-top: 80px;
    @include mq(tbl) {
      margin-top: 100px;
    }
  }
  @else if $size == xxlarge {
    margin-top: 100px;
    @include mq(tbl) {
      margin-top: 120px;
    }
  }
}


@mixin headingLP {
  text-align: center;
  i {
    font-weight: var(--bold);
    @include fs(16,22,50);
    margin-bottom: 10px;
    display: inline-block;
    @include mq(tbl) {
      @include fs(18,25,50);
    }
  }
  h1 {
    font-weight: var(--bold);
    @include fs(30,36,50);
    @include mq(tbl) {
      @include fs(36,46,50);
    }
  }
  p {
    margin-top: 30px;
    @include fs(14,24,50);
    @include mq(tbl) {
      @include fs(16,32,50);
    }
  }
}