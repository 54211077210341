@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.localnav_bottom {
  @include section;
  @include container;
  .inner {
    border-radius: var(--border-radius-small);
    background-color: var(--color-surface);
    padding: vw(32px) vw(24px);
    @include mq {
      padding: pc(64px) pc(64px);
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: vw(24px);
      @include mq {
        display: block;
        position: relative;
        padding-bottom: pc(40px);
      }
      h2 {
        font-weight: var(--bold);
        font-size: vw(--fs-l);
        @include mq {
          font-size: pc(--fs-xl);
        }
      }
    }
    ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: vw(20px);
      @include mq {
        grid-template-columns: repeat(3, 1fr);
        gap: pc(32px);
      }
      li {
        a {
          display: block;
          color: inherit;
          font-size: vw(--fs-s);
          line-height: var(--line-height-xsmall);
          @include mq {
            @include hoverColor;
            font-size: pc(--fs-l);
          }
          span {
            display: grid;
            grid-template-columns: auto auto;
            justify-content: start;
            gap: vw(8px);
            &:before {
              content: '';
              display: block;
              background: center / contain no-repeat url(/images/arrow_right.svg);
              width: vw(16px);
              height: 1lh;
            }
            @include mq {
              gap: pc(12px);
              &:before {
                width: pc(16px);
                translate: 0 pc(1px);
              }
            }
          }
        }
      }
    }
  }
}
