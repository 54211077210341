@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_member {
  @include module;
  .inner {
    @include container;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, auto);
    grid-gap: vw(30px) 0;
    @include mq {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: pc(32px) pc(32px);
    }
    .unit {
      .photo {
        margin-bottom: vw(32px);
        @include mq {
          margin-bottom: pc(42px);
        }
        img {
          aspect-ratio: 3 / 2;
          object-fit: cover;
          border-radius: var(--border-radius-small);
          
        }
      }
      .info {
        h3 {
          font-weight: var(--bold);
          font-size: vw(--fs-l);
          @include mq {
            font-size: pc(--fs-xl);
          }
          i {
            margin-top: vw(8px);
            display: block;
            font-size: vw(--fs-s);
            font-weight: var(--demi);
            @include mq {
              margin-top: pc(8px);
              font-size: pc(--fs-m);
            }
          }
        }
        .position {
          color: var(--color-third-weak);
          font-weight: var(--bold);
          font-size: vw(--fs-s);
          margin-top: vw(16px);
          @include mq {
            margin-top: pc(24px);
            font-size: pc(--fs-m);
          }
        }
        .profile {
          color: var(--color-black);
          margin-top: vw(12px);
          font-size: vw(--fs-s);
          line-height: var(--line-height-small);
          @include mq {
            margin-top: pc(24px);
            font-size: pc(--fs-s);
          }
        }
      }
      .desc {
        border-top: 1px solid var(--color-border);
        margin-top: vw(24px);
        padding-block: vw(24px);
        @include mq {
          margin-top: pc(24px);
          padding-block: pc(24px);
        }
        p {
          padding-bottom: 1em;
          font-size: vw(--fs-m);
          line-height: var(--line-height-small);
          @include mq {
            font-size: pc(--fs-m);
          }
          &:last-child {
            padding-bottom: unset;
          }
        }
        + .button {
          margin-top: unset;
        }
      }
      .button {
        text-align: center;
        padding-bottom: vw(20px);
        margin-top: vw(20px);
        @include mq {
          padding-bottom: pc(20px);
          margin-top: pc(20px);
        }
        a {
          @include button(small);
        }
      }
    }
  }
}
